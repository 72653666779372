import React, { useEffect, useState, useContext } from "react";
import "./TubeBlock.css";
import Measure from "react-measure";
import { useDispatch, useSelector } from "react-redux";
import { removeDevelopment } from "../../../../features/developments/developmentsSlice";
import {
  getIsFiltered,
  setIsFiltered,
} from "../../../../features/states/statesSlice";
import { getEditMode } from "../../../../features/app/UIRelatedSlice";

// const fillShape = "#bca7e2";
// const fillShape = "#A6DECF";
// const fillShape = "#c4bdbb";
// const fillShape = "#5b0f8d";
// const fillShape = "#e4d5d1";
// const fillShape = "#c1b1de";
// const fillShape = "#c8bdd1";
// const fillShape = "rgba(250, 192, 156)";
// const fillText = "black";
// const fillText = "#280844";
// const fillText = "#fff";
// const fillText = "rgb(19, 30, 49)";

function TubeBlock(props: any) {
  const dispatch = useDispatch();
  const [fillShape, setFillShape] = useState("#e4d5d1");
  const [fillText, setFillText] = useState("rgb(19, 30, 49)");
  const [rectX, setRectX] = useState(0);
  const [rectY, setRectY] = useState(0);
  const [rectWidth, setRectWidth] = useState(0);
  const [rectHeight, setRectHeight] = useState(0);

  const [stationDimensions, setStationDimensions] = useState({
    width: -1,
    height: -1,
  });
  const [devDimensions, setDevDimensions] = useState({ width: -1, height: -1 });

  const editMode = useSelector(getEditMode);
  const isFiltered = useSelector(getIsFiltered);

  useEffect(() => {
    if (isFiltered) {
      // setFillShape("#baa098");

      setFillShape("#d2b6ae");
      // setFillShape("#dcb7ad");
      // setFillShape("#dec4bd");
      const timer = setTimeout(() => {
        setFillShape("#e4d5d1");
        props.onColorChangeComplete();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isFiltered]);

  useEffect(() => {
    if (
      stationDimensions &&
      devDimensions &&
      document.querySelector(`#print-tube-view`)
    ) {
      const stationElement = document.querySelector(
        `#print-tube-view #idStationText${props.obj["stationId"]}`
      ) as SVGGraphicsElement;
      const devElement = document.querySelector(
        `#print-tube-view #idDevText${props.obj["stationId"]}`
      ) as SVGGraphicsElement;

      const {
        x: stationX,
        y: stationY,
        width: stationWidth,
        height: stationHeight,
      } = stationElement.getBBox();
      const { width: devWidth, height: devHeight } = devElement.getBBox();
      setRectX(stationX - 3);
      setRectY(stationY);
      const add = 5;
      setRectWidth((stationWidth >= devWidth ? stationWidth : devWidth) + add);
      setRectHeight(stationHeight + devHeight + 3);
    }
  }, [stationDimensions, devDimensions, props.obj]);

  return (
    <g transform={props.obj.transform}>
      <rect
        className="shape-container"
        x={rectX}
        y={rectY}
        width={rectWidth}
        height={rectHeight}
        rx="4"
        fill={fillShape}
      />
      {/* <a href="#" style={{ "pointerEvents": "none", "cursor": "default" }} > */}
      <Measure
        bounds
        onResize={(contentRect: any) => {
          setStationDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <text
            ref={measureRef}
            id={`idStationText${props.obj["stationId"]}`}
            className="station-name"
            x="0"
            y="0"
            fill={fillText}
          >
            {props.obj["station"]}:
          </text>
        )}
      </Measure>
      {/* </a> */}

      <Measure
        bounds
        onResize={(contentRect: any) => {
          setDevDimensions(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <g
            ref={measureRef}
            className="dev-on-map"
            id={`idDevText${props.obj["stationId"]}`}
            style={{ cursor: "pointer" }}
          >
            {props.obj.developments.map((development: any, index: any) => {
              const buttonX = rectX - 3;
              const buttonY = rectY + index * 11 + 19;
              return (
                // devInfo: [x.name, x["name-chinese"], x['unique-id'], x["wechatlink"], VALUE_FOR_Y, includeHouses]
                <React.Fragment key={index}>
                  <g
                    className="text-block"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.setCurrentObj(development);
                    }}
                  >
                    <text>
                      <tspan
                        className="dev-names"
                        id={"id-" + development.id}
                        x="0"
                        y={development.y}
                        fill={fillText}
                      >
                        {development.name}
                      </tspan>
                    </text>
                    {development.house &&
                      document.querySelector(`#id-${development.id}`) && (
                        <svg
                          id="house-svg"
                          data-t="1715350475467"
                          className="icon"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          p-id="7981"
                          width="10"
                          height="10"
                          x={
                            (
                              document.querySelector(
                                `#id-${development["id"]}`
                              ) as SVGGraphicsElement
                            ).getBBox().width + 3
                          }
                          y={
                            (
                              document.querySelector(
                                `#id-${development.id}`
                              ) as SVGGraphicsElement
                            ).getBBox().y +
                            (
                              document.querySelector(
                                `#id-${development.id}`
                              ) as SVGGraphicsElement
                            ).getBBox().height /
                              8
                          }
                        >
                          <path
                            d="M936.09073778 515.49525333c-1.16508445-6.40796445-6.40796445-13.98101333-6.99050667-14.56355555L791.62026667 364.61681778V239.95278222c0-12.81592889-10.48576-23.30168889-23.30168889-23.30168889h-39.03032889c-12.81592889 0-23.30168889 10.48576-23.30168889 23.30168889v23.30168889s0 3.49525333-1.16508445 4.66033778c-0.58254222 1.16508445-3.49525333 1.74762667-3.49525333 1.74762666s-4.07779555 1.16508445-5.82542222-0.58254222c-1.16508445-1.16508445-130.48945778-129.32437333-164.27690667-163.69436444-5.24288-5.24288-11.06830222-8.15559111-19.22389333-8.15559111S495.10627555 104.22044445 495.10627555 104.22044445l-396.1287111 396.71125333c-0.58254222 0.58254222-1.16508445 1.16508445-1.16508445 1.74762667-6.40796445 8.15559111-10.48576 23.88423111-2.91271111 41.94304l2.91271111 6.40796444c11.06830222 20.38897778 22.71914667 25.63185778 26.2144 26.79694222 1.16508445 0.58254222 2.91271111 0.58254222 4.07779555 0.58254222h79.22574223v311.66008889c0 11.65084445 11.65084445 25.63185778 25.63185777 25.63185778h560.40561778c15.72864 0 25.63185778-13.39847111 25.63185778-25.04931556v-311.66008889h79.80828444c2.33016889-0.58254222 23.30168889-4.66033778 31.45728-22.71914666 7.57304889-18.64135111 6.99050667-33.78744889 5.82542223-40.19541334z m-345.44753778 315.15534222H431.60917333c-2.33016889 0-3.49525333-1.74762667-4.07779555-3.49525333v-171.26741333c0-12.23338667 9.90321778-21.55406222 21.55406222-21.55406222h124.08149333c12.23338667 0 21.55406222 9.90321778 21.55406222 21.55406222V826.5728c0 2.91271111-1.74762667 4.07779555-4.07779555 4.07779555z"
                            fill="#3B086B"
                            p-id="7982"
                          ></path>
                        </svg>
                      )}
                  </g>

                  {editMode && (
                    <g
                      className="to-remove"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(removeDevelopment(+development.id));
                      }}
                    >
                      {/* <g onClick={(e) => { e.stopPropagation(); dispatch(removeDevelopment(development)); }}> */}
                      <circle
                        cx={buttonX}
                        cy={buttonY}
                        r={5}
                        fill="#A594B5"
                        stroke="#A594B5"
                        strokeWidth="1"
                      />
                      <line
                        x1={buttonX - 3}
                        y1={buttonY - 3}
                        x2={buttonX + 3}
                        y2={buttonY + 3}
                        stroke="white"
                        strokeWidth="1"
                      />
                      <line
                        x1={buttonX + 3}
                        y1={buttonY - 3}
                        x2={buttonX - 3}
                        y2={buttonY + 3}
                        stroke="white"
                        strokeWidth="1"
                      />
                    </g>
                  )}
                </React.Fragment>
              );
            })}
          </g>
        )}
      </Measure>
    </g>
  );

  // return (
  //     <g transform={props.obj.transform}>
  //         <rect className="shape-container" x={rectX} y={rectY} width={rectWidth} height={rectHeight} rx="4" fill={fillShape} />
  //                                         <a href="#" style={{ "pointerEvents": "none", "cursor": "default" }} >
  //                                             <text id={`idStationText${props.obj["unique-station-id"]}`} className="station-name" x="0" y="0" fill={fillText}>{props.obj["station-name"]}:</text>
  //                                         </a>

  //                                         <g className="dev-on-map" id={`idDevText${props.obj["unique-station-id"]}`} >
  //                                             {props.obj.developments.map((devInfo: any, index: any) => {
  //                                                 const uniqueStationId = devInfo[2];
  //                                                 // const uniqueStationId = props.obj["unique-station-id"];
  //                                                 const buttonX = rectX - 3; // Adjust as needed
  //                                                 const buttonY = rectY + (index * 20) + 19; // Adjust as needed

  //                                                 return (
  //                                                     // devInfo: [x.name, x["name-chinese"], x['unique-id'], x["wechatlink"], VALUE_FOR_Y, includeHouses]
  //                                                     <React.Fragment key={index}>
  //                                                         <a style={{ "pointerEvents": "none", "cursor": "default" }} href={devInfo[3] ? devInfo[3] : "#"} target={devInfo[3] ? "_blank" : ""} key={devInfo[0]}>
  //                                                             <text>
  //                                                                 {/* <tspan
  //                                     onClick={() => handleHideClick(uniqueStationId)}
  //                                 >Hide</tspan> */}
  //                                                                 <tspan className="dev-names" id={devInfo[2]} x="0" y={devInfo[5]} fill={fillText}>
  //                                                                     {devInfo[0]}
  //                                                                 </tspan>
  //                                                             </text>

  //                                                         </a>
  //                                                         {devInfo[4] && document.querySelector(`#${devInfo[2]}`) &&

  //                                                             <svg id="house-svg" data-t="1715350475467" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7981" width="10" height="10" x={(document.querySelector(`#${devInfo[2]}`) as SVGGraphicsElement).getBBox().width + 3} y={(document.querySelector(`#${devInfo[2]}`) as SVGGraphicsElement).getBBox().height / 4}>
  //                                                                 <path d="M936.09073778 515.49525333c-1.16508445-6.40796445-6.40796445-13.98101333-6.99050667-14.56355555L791.62026667 364.61681778V239.95278222c0-12.81592889-10.48576-23.30168889-23.30168889-23.30168889h-39.03032889c-12.81592889 0-23.30168889 10.48576-23.30168889 23.30168889v23.30168889s0 3.49525333-1.16508445 4.66033778c-0.58254222 1.16508445-3.49525333 1.74762667-3.49525333 1.74762666s-4.07779555 1.16508445-5.82542222-0.58254222c-1.16508445-1.16508445-130.48945778-129.32437333-164.27690667-163.69436444-5.24288-5.24288-11.06830222-8.15559111-19.22389333-8.15559111S495.10627555 104.22044445 495.10627555 104.22044445l-396.1287111 396.71125333c-0.58254222 0.58254222-1.16508445 1.16508445-1.16508445 1.74762667-6.40796445 8.15559111-10.48576 23.88423111-2.91271111 41.94304l2.91271111 6.40796444c11.06830222 20.38897778 22.71914667 25.63185778 26.2144 26.79694222 1.16508445 0.58254222 2.91271111 0.58254222 4.07779555 0.58254222h79.22574223v311.66008889c0 11.65084445 11.65084445 25.63185778 25.63185777 25.63185778h560.40561778c15.72864 0 25.63185778-13.39847111 25.63185778-25.63185778v-311.66008889h79.80828444c2.33016889-0.58254222 23.30168889-4.66033778 31.45728-22.71914666 7.57304889-18.64135111 6.99050667-33.78744889 5.82542223-40.19541334z m-345.44753778 315.15534222H431.60917333c-2.33016889 0-3.49525333-1.74762667-4.07779555-3.49525333v-171.26741333c0-12.23338667 9.90321778-21.55406222 21.55406222-21.55406222h124.08149333c12.23338667 0 21.55406222 9.90321778 21.55406222 21.55406222V826.5728c0 2.91271111-1.74762667 4.07779555-4.07779555 4.07779555z" fill="#3B086B" p-id="7982"></path>
  //                                                             </svg>
  //                                                             // <svg data-t="1715350475467" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7981" width="20" height="20" transform={`translate(${devInfo[0].length}, 0)`}><path d="M936.09073778 515.49525333c-1.16508445-6.40796445-6.40796445-13.98101333-6.99050667-14.56355555L791.62026667 364.61681778V239.95278222c0-12.81592889-10.48576-23.30168889-23.30168889-23.30168889h-39.03032889c-12.81592889 0-23.30168889 10.48576-23.30168889 23.30168889v23.30168889s0 3.49525333-1.16508445 4.66033778c-0.58254222 1.16508445-3.49525333 1.74762667-3.49525333 1.74762666s-4.07779555 1.16508445-5.82542222-0.58254222c-1.16508445-1.16508445-130.48945778-129.32437333-164.27690667-163.69436444-5.24288-5.24288-11.06830222-8.15559111-19.22389333-8.15559111S495.10627555 104.22044445 495.10627555 104.22044445l-396.1287111 396.71125333c-0.58254222 0.58254222-1.16508445 1.16508445-1.16508445 1.74762667-6.40796445 8.15559111-10.48576 23.88423111-2.91271111 41.94304l2.91271111 6.40796444c11.06830222 20.38897778 22.71914667 25.63185778 26.2144 26.79694222 1.16508445 0.58254222 2.91271111 0.58254222 4.07779555 0.58254222h79.22574223v311.66008889c0 11.65084445 11.65084445 25.63185778 25.63185777 25.63185778h560.40561778c15.72864 0 25.63185778-13.39847111 25.63185778-25.63185778v-311.66008889h79.80828444c2.33016889-0.58254222 23.30168889-4.66033778 31.45728-22.71914666 7.57304889-18.64135111 6.99050667-33.78744889 5.82542223-40.19541334z m-345.44753778 315.15534222H431.60917333c-2.33016889 0-3.49525333-1.74762667-4.07779555-3.49525333v-171.26741333c0-12.23338667 9.90321778-21.55406222 21.55406222-21.55406222h124.08149333c12.23338667 0 21.55406222 9.90321778 21.55406222 21.55406222V826.5728c0 2.91271111-1.74762667 4.07779555-4.07779555 4.07779555z" fill="#3B086B" p-id="7982"></path></svg>
  //                                                         }
  //                                                         {editMode &&

  //                                                             <g onClick={() => props.handleHideClick(uniqueStationId)} style={{ cursor: 'pointer' }}>
  //                                                                 <circle
  //                                                                     cx={buttonX}
  //                                                                     cy={buttonY}
  //                                                                     r={6}
  //                                                                     fill="white"
  //                                                                     stroke="rgb(73, 93, 121)"
  //                                                                     strokeWidth="1"
  //                                                                 />
  //                                                                 <line
  //                                                                     x1={buttonX - 3}
  //                                                                     y1={buttonY - 3}
  //                                                                     x2={buttonX + 3}
  //                                                                     y2={buttonY + 3}
  //                                                                     stroke="rgb(73, 93, 121)"
  //                                                                     strokeWidth="1"
  //                                                                 />
  //                                                                 <line
  //                                                                     x1={buttonX + 3}
  //                                                                     y1={buttonY - 3}
  //                                                                     x2={buttonX - 3}
  //                                                                     y2={buttonY + 3}
  //                                                                     stroke="rgb(73, 93, 121)"
  //                                                                     strokeWidth="1"
  //                                                                 />
  //                                                             </g>
  //                                                         }
  //                                                     </React.Fragment>

  //                                                 );

  //                                             })}
  //                                         </g>
  //                                     </g >
  // );
}

export default TubeBlock;
