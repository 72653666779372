import React, { useEffect, useMemo } from "react";
import styles from "./ListView.module.css";
import ListBlock from "../Components/List/ListBlock";
import { useDispatch, useSelector } from "react-redux";
import { clearFilter, getAllDevelopments, getSortedDevelopmentIds } from "../../../features/developments/developmentsSlice";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import imgNoResult from "../../../assets/media/background/no-result.png";
import { getLang } from "../../../features/app/UserRelatedSlice";
import { getShowFilter } from "../../../features/app/UIRelatedSlice";
import { getLayoutHeaderHeight, getLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";

function importAll(r: any) {
  let images: any = {};
  r.keys().forEach((item: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context(
    "../../../assets/media/developmentImages",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const ListView = React.memo((props: any) => {
  const sortedDevelopmentIds = useSelector(getSortedDevelopmentIds);
  const allDevelopments = useSelector(getAllDevelopments);
  const showFilter = useSelector(getShowFilter);
  const lang = useSelector(getLang);
  const developmentData = useMemo(() => {
    return sortedDevelopmentIds.map((id: any) => {
      const obj = allDevelopments[id];
      const imageId = String(obj.id).padStart(4, '0');
      const imageUrl = images[`NV${imageId.slice(-4)}.jpg`];
      return { ...obj, url: imageUrl };
    });
  }, [sortedDevelopmentIds, allDevelopments]);

  if (developmentData.length === 0) {
    return <NoResult />;
  }

  return (
    <>
      <div className={`h-full ${styles.gridContainer} ${showFilter && styles.withFilter} relative`}
        id="print-list-view">

        {developmentData.map((obj: any) => {
          return (
            <ListBlock
              key={obj["id"]}
              obj={obj}
              renderWithSort={props.renderWithSort}
              sort={props.sort}
            />
          );
        })}
      </div>
      <p className="including-houses text-secondary-dark/70" style={{ marginLeft: "60px", marginBottom: "40px", fontSize: "20px", display: "flex", alignItems: "center" }}>
        <IncludeHouseIcon width="18" height="18" />&nbsp;
        <span className="text-base font-semibold pt-[1px]">{lang ? "Including houses" : "包括房屋"}</span>
      </p>
    </>
  );
});

const NoResult = React.memo(() => {
  const headerHeight = useSelector(getLayoutHeaderHeight);
  const menubarHeight = useSelector(getLayoutMenubarHeight);
  const dispatch = useDispatch();
  return (
    <div className="flex h-full w-full items-center justify-center"
      style={{ height: `calc(-${headerHeight + menubarHeight}px + 100vh)` }}>
      <img className="absolute top-0 left-0 w-full h-full object-cover filter grayscale opacity-15" src={imgNoResult} alt="decorative illustration for no result" />
      <div className="relative z-10 flex flex-col -mt-20 items-center justify-center text-center">
        <p className="text-2xl font-semibold text-gray-700 mb-4">No results found</p>
        <p className="text-lg text-gray-600 mb-6">Try adjusting your search or filters to find your dream property.</p>
        <button
          className="px-4 py-2 bg-main text-white rounded-lg hover:bg-secondary-dark transition duration-300"
          onClick={() => {
            dispatch(clearFilter());
            // setLocalFilters(initialFilter);
          }}
        >
          Reset Filters
        </button>
      </div>
    </div>
  );
});

export default ListView;
