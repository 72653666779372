import React, { useEffect, useState } from 'react';

interface FloatingNotificationProps {
    message: string;
    duration?: number;
}

const FloatingNotification: React.FC<FloatingNotificationProps> = ({ message, duration = 2000 }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);

    if (!isVisible) return null;

    return (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-70 text-white p-4 rounded-md transition-opacity duration-300">
            {message}
        </div>
    );
};

export default FloatingNotification;