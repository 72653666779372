import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLang, setPermission } from "../../features/app/UserRelatedSlice";
import { setShowPermissionPopup } from "../../features/app/UIRelatedSlice";

export default function PermissionPopup() {
  const lang = useSelector(getLang);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  function handlePermission(e: React.FormEvent) {
    // setShowPermissionPopup(true);
    e.preventDefault();
    if (password === "NV2024!") {
      dispatch(setPermission(5));
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      localStorage.setItem("api_key", "3fa85f64c2f6803e");
      // document.cookie = "api_key=3fa85f64c2f6803e; expires=" + date.toUTCString() + "; domain=.siqisu.co.uk; path=/; SameSite=None; Secure";
      // document.cookie = "api_key=3fa85f64c2f6803e; expires=" + date.toUTCString() + "; SameSite=None; Secure";

      // document.cookie = "api_key=3fa85f64c2f6803e; expires=" + date.toUTCString();
      dispatch(setShowPermissionPopup(false));
    }
  }

  return (
    <div className="fixed top-0 right-0 z-[99999999999999] w-screen h-screen flex items-center justify-center bg-black/30">
      <form
        className="relative bg-white p-10 rounded-lg flex gap-6 flex-col"
        onSubmit={handlePermission}
      >
        <p
          className="absolute top-3 right-3 hover:cursor-pointer"
          onClick={() => {
            dispatch(setShowPermissionPopup(false));
          }}
        >
          ✕
        </p>
        <label className="text-secondary-dark font-medium text-lg">
          {lang ? "Please enter your password below:" : "请输入您的密码："}
        </label>
        <div>
          <input
            title="password"
            type="password"
            className="input input-bordered"
            required
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value || "");
            }}
          />
          <button type="submit" className="btn bg-secondary-light/20 ml-2">
            {lang ? "Submit" : "提交"}
          </button>
        </div>
      </form>
    </div>
  );
}
