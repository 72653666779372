// src/components/HtmlOverflowManager.tsx
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsRealMobile, getShowActionPopup, getShowContactPopup, getShowFilter } from '../features/app/UIRelatedSlice';
import { getPdfStatus } from '../features/states/statesSlice';


const HtmlOverflowManager: React.FC = React.memo(() => {
    const showContactPopup = useSelector(getShowContactPopup);
    const showActionPopup = useSelector(getShowActionPopup);
    const isRealMobile = useSelector(getIsRealMobile);
    const showFilter = useSelector(getShowFilter);
    const pdfStatus = useSelector(getPdfStatus);

    useEffect(() => {
        const html = document.querySelector("html");
        if (showContactPopup || showActionPopup) {
            if (html) html.style.overflow = "hidden";
        } else {
            if (html) html.style.overflow = "unset";
        }
    }, [showContactPopup, showActionPopup]);


    useEffect(() => {
        if (!isRealMobile) return;
        if (window.location.pathname !== "/property") return;
        const html = document.querySelector("html");
        if (showFilter) {
            if (html) html.style.overflow = "hidden";
        } else {
            if (html) html.style.overflow = "unset";
        }
    }, [showFilter, isRealMobile]);

    useEffect(() => {
        const html = document.querySelector("html");
        const header = document.querySelector("header");
        if (header) {
            if (pdfStatus !== "hide") {
                if (html) html.style.overflow = "hidden";
                // header.style.zIndex = "99";
                header.style.filter = "brightness(0.8)";
                header.style.transition = "filter 0.1s ease-in-out";
            } else {
                if (html) html.style.overflow = "unset";
                header.style.filter = "brightness(1)";
                header.style.transition = "filter 0.1s ease-in-out";
            }
        }
    }, [pdfStatus]);



    return null; // This component doesn't render anything
});

export default HtmlOverflowManager;