import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from '@reduxjs/toolkit';


interface StatesState {
    pdfStatus: "idle" | "preparing" | "generating" | "ready" | "error" | "hide";
    isFiltered: boolean;
    shouldResetTransform: boolean;

}

const initialState: StatesState = {
    pdfStatus: "hide",
    isFiltered: false, // for TubeView's temporary color change
    shouldResetTransform: false,

};



// SLICE OBJECT
export const statesSlice = createSlice({
    name: "states",
    initialState,
    reducers: {
        setPdfStatus: (state, action: PayloadAction<StatesState['pdfStatus']>) => {
            state.pdfStatus = action.payload;
        },
        setIsFiltered: (state, action: PayloadAction<boolean>) => {
            state.isFiltered = action.payload;
        },
        resetTransform: (state) => {
            state.shouldResetTransform = true;
        },
        clearTransformReset: (state) => {
            state.shouldResetTransform = false;
        },
    },
});

// SELECTORS
export const getPdfStatus = (state: any) => state.states.pdfStatus;
export const getIsFiltered = (state: any) => state.states.isFiltered;
export const getShouldResetTransform = (state: any) => state.states.shouldResetTransform;
// EXPORTS
export const {
    setPdfStatus,
    setIsFiltered,
    resetTransform,
    clearTransformReset,
} = statesSlice.actions;
export default statesSlice.reducer;
