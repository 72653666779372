import React from "react";
import imgMap from "../../assets/media/developmentMaps/map.jpeg";
import imgList from "../../assets/media/developmentMaps/list.jpeg";
import logo from "../../assets/media/logo/company/nvreLogoPurple.png";
import { createDriver } from "../../pages/Sale/SalePage";
import { getLang } from "../../features/app/UserRelatedSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsRealMobile,
  setShowActionPopup,
  setShowContactPopup,
} from "../../features/app/UIRelatedSlice";

export default function ActionPopup() {
  const dispatch = useDispatch();
  const isRealMobile = useSelector(getIsRealMobile);
  const lang = useSelector(getLang);

  return (
    <div
      className={`fixed w-screen h-screen top-0 left-0 flex items-center justify-center z-[9999999999999999999] bg-transparent ${
        isRealMobile ? "h-[95vh] overflow-y-scroll" : ""
      }`}
    >
      <div className="mx-3 relative flex flex-col bg-white p-10 max-w-[800px] border shadow-lg rounded-md">
        <p
          className="absolute top-4 right-4 hover:cursor-pointer text-black/40"
          onClick={() => {
            dispatch(setShowActionPopup(false));
          }}
        >
          ✕
        </p>
        <img className="w-32 opacity-20" src={logo} alt="NVRE logo" />
        <p className="text-secondary-dark font-medium text-lg mt-5">
          {lang
            ? "We will assist you in finding the perfect development that suits your needs."
            : "我们将协助您找到最适合您需求的房产。"}
        </p>
        <div className="text-nowrap flex gap-3 mt-5 self-center flex-col sm:flex-row">
          <button
            className="btn btn-sm  bg-main text-white rounded-sm"
            onClick={() => {
              dispatch(setShowActionPopup(false));
              const driverObj = createDriver(lang);
              driverObj.drive();
            }}
          >
            {lang ? "Show me how" : "向我展示如何操作"}
          </button>
          <button
            className="btn btn-sm  btn-outline  text-main rounded-sm"
            onClick={() => {
              dispatch(setShowActionPopup(false));
            }}
          >
            {lang ? "I will explore on my own" : "我会自己探索"}
          </button>
        </div>
        <p className="font-bold mt-10 mb-2 text-lg text-gray-800 border-t border-gray-100 pt-10">
          {lang
            ? "You can save your choices as a PDF:"
            : "您可以将您的选择保存为PDF:"}
        </p>
        <div className={`flex gap-3`}>
          <img
            className={`${isRealMobile ? "h-20" : "h-40"} shadow-lg border`}
            src={imgMap}
            alt="a development map"
          />
          <img
            className={`${isRealMobile ? "h-20" : "h-40"} shadow-lg border`}
            src={imgList}
            alt="a development map"
          />
        </div>
        <div className="mt-12">
          <p className="text-lg font-bold text-gray-800">
            {lang ? "We are here to help!" : "我们在这里帮助您！"}
          </p>
          <ul className="list-disc text-sm list-inside">
            <li className="text-inherit-size">
              {lang ? "Not sure how to start?" : "不确定如何开始？"}
            </li>
            <li className="text-inherit-size">
              {lang
                ? "Not sure which property you should buy?"
                : "不确定您应该购买哪套房产？"}
            </li>
            <li className="text-inherit-size">
              {lang ? "Need help with anything else?" : "需要其他帮助？"}
            </li>
          </ul>
          <button
            className="mt-2 text-main font-bold underline underline-offset-2 text-lg"
            onClick={() => {
              dispatch(setShowActionPopup(false));
              dispatch(setShowContactPopup(true));
            }}
          >
            {lang ? "Contact Us" : "联系我们"}
          </button>
        </div>
      </div>
    </div>
  );
}
