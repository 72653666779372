import React, { useContext, useState } from "react";
import Info from "./Info";
import Contact from "../../../Contact/Contact";
import { useSelector } from "react-redux";
import {
  getIsMobile,
  getIsRealMobile,
} from "../../../../features/app/UIRelatedSlice";

export default function Popup(props: any) {
  const isMobile = useSelector(getIsMobile);
  const isRealMobile = useSelector(getIsRealMobile);

  const urlParams = new URLSearchParams(window.location.search);
  const viewParam = urlParams.get("view");
  return (
    <div
      // className={` ${isMobile ? (props.isMap ? "" : "w-full shadow-lg ") : (!props.isMap ? `absolute top-8 w-[400px] ${isLeft ? "left-24" : "left-24"}` : "w-[400px]")} bg-white  shadow-lg border rounded-lg`}
      // className={`flex ${isMobile ? (props.isMap ? "" : "w-full shadow-lg ") : (props.isMap ? `absolute top-8 w-[560px] ${isLeft ? "left-24" : "right-12 left-auto"}` : "w-[400px]")} bg-white  shadow-lg border rounded-lg z-[9999999999]`}
      className={`flex ${
        isMobile
          ? props.isMap
            ? ""
            : "w-full shadow-lg "
          : props.isMap
          ? `w-[560px]`
          : "w-[400px]"
      } bg-white  shadow-lg border rounded-lg z-[9999999999]`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <p
        className={` absolute ${
          isMobile
            ? "top-6 left-7 text-white font-thin text-lg"
            : "top-2 left-4 text-white font-bold text-xl"
        }    hover:cursor-pointer`}
      >
        {viewParam === "tube" && !isMobile && (
          <>
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.setIsLeft(!props.isLeft);
              }}
            >
              {props.isLeft ? "→" : "←"}
            </span>
            <span>&nbsp;&nbsp;</span>
          </>
        )}
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.setCurrentObj(null);
          }}
        >
          ✕
        </span>
      </p>
      <div className={`${isMobile ? "flex h-full " : "flex"}`}>
        <Info obj={props.obj} customiseImg={true} />
      </div>
      {!isMobile && (
        <div className="p-6 border-t border-t-gray-200/70 bg-secondary-light/5">
          <Contact page="onmap" />
        </div>
      )}
    </div>
  );
}
