import React from "react";
import "./Policies.scss";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/UserRelatedSlice";

export function PoliciesCookies() {
  const lang = useSelector(getLang);
  return (
    <>
      <Helmet>
        <title>Cookies Policy | NVRE</title>
        <meta
          name="description"
          content="Learn about how NVRE uses cookies and similar technologies to improve your browsing experience on our website."
        />
        <meta
          name="keywords"
          content="cookies policy, NVRE cookies, website tracking, cookie preferences"
        />
      </Helmet>
      <div className={"policy-container policies-cookies-container"}>
        <h1>{lang ? "Cookies Policy" : "Cookie 使用政策"}</h1>
        <p>
          {lang
            ? `This Cookie Policy explains how NVRE ("we," "us," or "our") uses cookies and similar tracking technologies when you visit our website. By using our website, you consent to the use of cookies as described in this policy.`
            : `本Cookie政策说明了NVRE（"我们"、"我们的"）在您访问我们的网站时如何使用cookie和类似的跟踪技术。使用我们的网站即表示您同意按照本政策中的说明使用cookie。`}
        </p>
        <ul>
          <li>
            <h2>1. {lang ? "What are Cookies?" : "什么是Cookie"}</h2>
            {lang
              ? `Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit a website. They enable the website to remember your actions and preferences (such as login details, language, font size, and other display preferences) over a certain period. Cookies serve various purposes, including making the website more user-friendly, analyzing website usage, and providing personalized content.`
              : `Cookie是存储在您的设备（计算机、平板电脑或手机）上的小型文本文件，当您访问网站时。它们使网站能够记住您的操作和偏好（如登录详情、语言、字体大小和其他显示偏好）。Cookie服务于各种目的，包括使网站更加用户友好、分析网站使用情况和提供个性化内容。`}
          </li>
          <li>
            <h2>
              2. {lang ? "Types of Cookies We Use" : "我们使用的Cookie类型"}
            </h2>
            <ul>
              <li>
                <b>2.1. {lang ? "Necessary Cookies:" : "必要Cookie："}</b>{" "}
                {lang
                  ? "These cookies are essential for the operation of our website. They enable you to navigate the site, use its features, and access secure areas. Without these cookies, certain services may not be available to you."
                  : "这些cookie对于我们网站的运行至关重要。它们使您能够浏览网站、使用其功能并访问安全区域。如果没有这些cookie，某些服务可能无法为您提供。"}
              </li>
              <li>
                <b>2.2. {lang ? "Analytics Cookies:" : "分析Cookie："}</b>{" "}
                {lang
                  ? "We use analytics cookies to collect information about how visitors use our website, such as the pages visited, the time spent on each page, and the referring website. This information helps us understand and improve the performance of our website."
                  : "我们使用分析cookie来收集有关访问者如何使用我们网站的信息，例如访问的页面、在每个页面上花费的时间以及引荐网站。这些信息帮助我们了解和改进网站的性能。"}
              </li>
              <li>
                <b>2.3. {lang ? "Preference Cookies:" : "偏好Cookie："}</b>{" "}
                {lang
                  ? "Preference cookies are used to remember your preferences and settings on the website, such as language preferences and display settings. They enhance your browsing experience and make your interactions with the website more personalized."
                  : "偏好cookie用于记住您在网站上的偏好和设置，例如语言偏好和显示设置。它们增强您的浏览体验，使您与网站的互动更加个性化。"}
              </li>
              <li>
                <b>2.4. {lang ? "Marketing Cookies:" : "营销Cookie："}</b>{" "}
                {lang
                  ? "We may use marketing cookies to track your interactions with our website and display targeted advertisements based on your interests. These cookies may be set by us or by third-party advertising partners."
                  : "我们可能使用营销cookie来跟踪您与我们网站的互动，并根据您的兴趣显示定向广告。这些cookie可能由我们或第三方广告合作伙伴设置。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>3. {lang ? "Third-Party Cookies" : "第三方Cookie"}</h2>
            <p>
              {lang
                ? `We may also allow third-party service providers, such as analytics or advertising companies, to place cookies on our website. These third parties may collect information about your online activities over time and across different websites. We do not have control over these third-party cookies or their privacy practices. We recommend reviewing the respective privacy policies of these third parties.`
                : `我们可能允许第三方服务提供商（如分析或广告公司）在我们的网站上放置cookie。这些第三方可能收集有关您在线活动的信息，并跨不同网站进行跟踪。我们无法控制这些第三方cookie或其隐私实践。我们建议查看这些第三方各自的隐私政策。`}
            </p>
          </li>
          <li>
            <h2>
              4. {lang ? "Cookie Consent and Control" : "Cookie同意和控制"}
            </h2>
            <p>
              {lang
                ? `By using our website, you consent to the placement of cookies on your device as described in this Cookie Policy. You can control and manage cookies in various ways. Most web browsers allow you to accept or reject cookies or notify you when a cookie is being sent. However, please note that disabling or blocking certain cookies may impact the functionality and performance of the website.`
                : `通过使用我们的网站，您同意在我们的网站上放置cookie。您可以通过多种方式控制和管理cookie。大多数网络浏览器允许您接受或拒绝cookie，或通知您当cookie被发送时。但是，请注意，禁用或阻止某些cookie可能会影响网站的功能和性能。`}
            </p>
          </li>
          <li>
            <h2>
              5. {lang ? "Updates to the Cookie Policy" : "Cookie政策更新"}
            </h2>
            <p>
              {lang
                ? `We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this Cookie Policy periodically for any updates.`
                : `我们可能会不时更新此Cookie政策，以反映我们实践或适用法律的变化。我们鼓励您定期查看此Cookie政策以获取任何更新。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Contact Us" : "联系我们"}</h2>
            <p>
              {lang ? (
                <>
                  If you have any questions or concerns about our use of cookies
                  or this Cookie Policy, please contact us at{" "}
                  <a href="mailto:contact@nvre.co.uk">contact@nvre.co.uk</a>.
                </>
              ) : (
                <>
                  如果您对我们的cookie使用或此Cookie政策有任何疑问或关注，请通过电子邮件与我们联系：
                  <a href="mailto:contact@nvre.co.uk">contact@nvre.co.uk</a>。
                </>
              )}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export function PoliciesPrivacy() {
  const lang = useSelector(getLang);
  return (
    <>
      <Helmet>
        <title>Privacy Policy | NVRE</title>
        <meta
          name="description"
          content="NVRE's Privacy Policy outlines how we collect, use, protect, and handle your personal information when you use our services."
        />
        <meta
          name="keywords"
          content="privacy policy, NVRE privacy, data protection, personal information, privacy terms"
        />
      </Helmet>
      <div className={"policy-container policies-privacy-container"}>
        <h1>{lang ? "Privacy Policy" : "隐私政策"}</h1>
        <p>
          {lang
            ? `At NVRE, we are committed to protecting the privacy and personal
          information of our website visitors, clients, and tenants. This
          Privacy Policy outlines how we collect, use, disclose, and protect the
          information we gather through our website and in the course of
          providing our services. By accessing or using our website or engaging
          with our services, you consent to the terms outlined in this Privacy
          Policy.`
            : `在NVRE，我们致力于保护网站访问者、客户和租户的隐私和个人信息。本隐私政策说明了我们如何收集、使用、披露和保护通过我们的网站和提供服务过程中收集的信息。通过访问或使用我们的网站或与我们互动，您同意遵守本隐私政策中的条款。`}
        </p>
        <ul>
          <li>
            <h2>{lang ? "Information Collection" : "信息收集"}</h2>
            <p>
              {lang
                ? `We may collect the following types of information:`
                : `我们可能收集以下类型的信息：`}
            </p>
            <ul>
              <li>
                {lang ? (
                  <>
                    <b>Personal Information:</b> When you voluntarily provide it
                    to us, we may collect personal information such as your
                    name, email address, phone number, and any other information
                    you provide when contacting us or using our services.
                  </>
                ) : (
                  <>
                    <b>个人信息：</b>
                    当您自愿向我们提供时，我们可能收集个人信息，如您的姓名、电子邮件地址、电话号码以及您在联系我们或使用我们的服务时提供的任何其他信息。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>Website Usage Information:</b> We may collect
                    non-personal information about your interactions with our
                    website, including IP addresses, browser details, device
                    information, and website activity. This information helps us
                    understand how visitors engage with our website and improve
                    our services.
                  </>
                ) : (
                  <>
                    <b>网站使用信息：</b>
                    我们可能收集有关您与我们网站互动的非个人信息，包括IP地址、浏览器详细信息、设备信息和网站活动。这些信息帮助我们了解访客如何与我们网站互动，并改进我们的服务。
                  </>
                )}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "Information Use" : "信息使用"}</h2>
            <p>
              {lang
                ? `We may use the collected information for the following purposes:`
                : `我们可能出于以下目的使用收集到的信息：`}
            </p>
            <ul>
              <li>
                {lang ? (
                  <>
                    <b>Providing Services: </b>
                    To respond to your inquiries, provide requested services,
                    and communicate with you regarding property listings,
                    appointments, or other relevant matters.
                  </>
                ) : (
                  <>
                    <b>提供服务：</b>
                    响应您的询问、提供请求的服务，并与您沟通有关物业列表、预约或其他相关事宜。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b> Marketing and Communication: </b>
                    To send you marketing communications, newsletters, updates,
                    or other promotional materials that may be of interest to
                    you. You can opt-out of these communications at any time.
                  </>
                ) : (
                  <>
                    <b>营销和沟通：</b>
                    向您发送营销通信、新闻通讯、更新或其他促销材料，这些材料可能对您有兴趣。您可以随时选择退出这些通信。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>Improving Our Services: </b>
                    To analyze website usage patterns, gather feedback, and
                    enhance our website and services based on user preferences
                    and needs.
                  </>
                ) : (
                  <>
                    <b>改进我们的服务：</b>
                    分析网站使用模式、收集反馈并根据用户偏好和需求增强我们的网站和服务。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>Legal Compliance:</b> To comply with legal obligations,
                    enforce our terms and conditions, protect our rights, and
                    address any potential fraud or security issues.
                  </>
                ) : (
                  <>
                    <b>遵守法律义务：</b>
                    遵守法律义务、执行我们的条款和条件、保护我们的权利并解决任何潜在的欺诈或安全问题。
                  </>
                )}
              </li>
            </ul>
          </li>
          <li>
            <h2>
              {lang ? "Information Sharing and Disclosure" : "信息共享和披露"}
            </h2>
            <p>
              {lang
                ? `We may share your information in the following circumstances:`
                : `我们可能出于以下情况分享您的信息：`}
            </p>
            <ul>
              <li>
                {lang ? (
                  <>
                    <b>Service Providers: </b>
                    We may engage trusted third-party service providers who
                    assist us in delivering our services. These service
                    providers have access to the necessary information to
                    perform their functions but are obligated to maintain the
                    confidentiality and security of your information.
                  </>
                ) : (
                  <>
                    <b>服务提供商：</b>
                    我们可能与可信赖的第三方服务提供商合作，这些服务提供商协助我们提供服务。这些服务提供商有权访问必要的个人信息以履行其职能，但必须遵守保密和安全义务。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>Legal Compliance:</b> We may share your information when
                    required by law, court order, or legal process, or if we
                    believe in good faith that such disclosure is necessary to
                    protect our rights, safety, or the rights of others.
                  </>
                ) : (
                  <>
                    <b>遵守法律义务：</b>
                    当法律要求、法院命令或法律程序需要时，或者如果我们有理由相信这种披露是必要保护我们的权利、安全或他人的权利时，我们可能会分享您的信息。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>Business Transfers:</b> In the event of a merger,
                    acquisition, or transfer of assets, your information may be
                    transferred as part of the transaction. We will take
                    appropriate measures to ensure the continued protection of
                    your informati on.
                  </>
                ) : (
                  <>
                    <b>业务转让：</b>{" "}
                    在合并、收购或资产转让的情况下，您的信息可能会作为交易的一部分进行转让。我们将采取适当措施，确保您的信息继续得到保护。
                  </>
                )}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "Data Security" : "数据安全"}</h2>
            <p>
              {lang
                ? `We employ appropriate security measures to protect your
              information from unauthorized access, alteration, disclosure, or
              destruction. However, please note that no method of data
              transmission over the internet or electronic storage is 100%
              secure, and we cannot guarantee absolute security.`
                : `我们采取适当的安全措施，保护您的信息免受未经授权的访问、修改、披露或破坏。但是，请注意，任何通过互联网或电子存储的数据传输方法都不是100%安全的，我们无法保证绝对安全。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Data Retention" : "数据保留"}</h2>
            <p>
              {lang
                ? `We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.`
                : `我们保留您的个人信息，以满足本隐私政策中规定的目的，除非法律要求更长的保留期限。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Your Rights and Choices" : "您的权利和选择"}</h2>
            <p>
              {lang
                ? `You have the right to access, correct, update, or delete your
              personal information, subject to any legal requirements. You may
              also have the right to restrict or object to certain processing
              activities. To exercise these rights or make any inquiries
              regarding your personal information, please contact us using the
              information provided below.`
                : `您有权访问、更正、更新或删除您的个人信息，但须遵守任何法律要求。您可能还有权限制或反对某些处理活动。要行使这些权利或对您的个人信息提出任何疑问，请通过以下提供的联系方式联系我们。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Third-Party Links" : "第三方链接"}</h2>
            <p>
              {lang
                ? `Our website may contain links to third-party websites. We are not
              responsible for the privacy practices or content of those
              websites. We encourage you to review the privacy policies of any
              third-party websites you visit.`
                : `我们的网站可能包含指向第三方网站的链接。我们不对这些网站的隐私实践或内容负责。我们鼓励您查看任何第三方网站的隐私政策。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Updates to Privacy Policy" : "隐私政策更新"}</h2>
            <p>
              {lang
                ? `We may update this Privacy Policy from time to time. We
              encourage you to review this Privacy Policy periodically to
              stay informed of any updates.`
                : `我们可能会不时更新此隐私政策。我们鼓励您定期查看此隐私政策以了解任何更新。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Contact Us" : "联系我们"}</h2>
            <p>
              {lang
                ? `If you have any questions, concerns, or requests regarding this
              Privacy Policy or our privacy practices, please contact us:`
                : `如果您有任何疑问、关注或请求，请通过以下联系方式联系我们：`}
            </p>
            <p className="mt-4">
              <b>NVRE</b>
            </p>
            <p>16 Berkeley Street</p>
            <p>Mayfair</p>
            <p>London, W1J 8DZ</p>
            <p>UK</p>
            <p className="mt-4">
              <b>{lang ? "Email:" : "电子邮件:"}</b>{" "}
              <a href="mailto:contact@nvre.co.uk">contact@nvre.co.uk</a>
            </p>
            <p>
              <b>{lang ? "Office:" : "办公室:"}</b> +44 (0)20 3907 4024
            </p>

            <p className="mt-4">
              {lang
                ? `We are committed to addressing your inquiries and resolving any
              concerns to ensure the protection of your privacy.`
                : `我们致力于解决您的疑问并解决任何关注，以确保保护您的隐私。`}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export function PoliciesTerms() {
  const lang = useSelector(getLang);
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | NVRE</title>
        <meta
          name="description"
          content="Read NVRE's Terms and Conditions to understand your rights and obligations when using our website and services."
        />
        <meta
          name="keywords"
          content="terms and conditions, NVRE terms, legal terms, user agreement"
        />
      </Helmet>
      <div className={"policy-container policies-terms-container"}>
        <h1>{lang ? "Terms and Conditions" : "条款和条件"}</h1>
        <p>
          {lang
            ? `These Terms and Conditions ("Terms") govern your access to and use of
          the NVRE website ("www.nvre.co.uk"). By accessing or using the
          Website, you agree to be bound by these Terms. If you do not agree
          with any part of these Terms, please refrain from using the Website.`
            : `这些条款和条件（“条款”）规范您对NVRE网站（“www.nvre.co.uk”）的访问和使用。通过访问或使用该网站，您同意遵守这些条款。如果您不同意这些条款的任何部分，请不要使用该网站。`}
        </p>
        <ul>
          <li>
            <h2>{lang ? "1. Website Use" : "1. 网站使用"}</h2>
            <ul>
              <li>
                {lang ? (
                  <>
                    <b>1.1. Eligibility:</b> You must be at least 18 years old
                    and have the legal capacity to enter into a binding
                    agreement to use the Website.{" "}
                  </>
                ) : (
                  <>
                    <b>1.1. 资格：</b>
                    您必须年满18岁，并具有签订具有约束力的协议的法律能力才能使用本网站。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>1.2. Permitted Use:</b> You agree to use the Website
                    solely for lawful purposes and in accordance with these
                    Terms. Any unauthorized use of the Website is strictly
                    prohibited.
                  </>
                ) : (
                  <>
                    <b>1.2. 允许使用：</b>
                    您同意仅出于合法目的并遵守这些条款使用本网站。任何未经授权的使用本网站的行为都是严格禁止的。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>1.3. Account Creation:</b> Some features or services on
                    the Website may require you to create an account. You are
                    responsible for providing accurate and up-to-date
                    information during the account creation process and
                    maintaining the confidentiality of your account credentials.
                  </>
                ) : (
                  <>
                    <b>1.3. 账户创建：</b>
                    本网站的某些功能或服务可能需要您创建账户。您有责任在账户创建过程中提供准确和最新的信息，并维护您的账户凭证的机密性。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>1.4. User Content:</b> You may have the opportunity to
                    submit or post content on the Website. By doing so, you
                    grant NVRE a non-exclusive, worldwide, royalty-free,
                    perpetual, and transferable license to use, reproduce,
                    modify, adapt, distribute, and display the content for the
                    purposes of operating and improving the Website.
                  </>
                ) : (
                  <>
                    <b>1.4. 用户内容：</b>
                    您可能有机会在网站上提交或发布内容。通过这样做，您授予NVRE非独占的、全球性的、免版税的、永久的和可转让的许可，以使用、复制、修改、改编、分发和显示内容，用于运营和改进网站的目的。
                  </>
                )}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "2. Intellectual Property" : "2. 知识产权"}</h2>
            <ul>
              <li>
                {lang ? (
                  <>
                    <b>2.1. Ownership:</b> The Website and its contents,
                    including but not limited to text, graphics, logos, images,
                    and software, are the property of NVRE and are protected by
                    intellectual property laws. You may not use, reproduce,
                    modify, or distribute any content from the Website without
                    prior written consent from NVRE.
                  </>
                ) : (
                  <>
                    <b>2.1. 所有权：</b>
                    本网站及其内容，包括但不限于文本、图形、标志、图像和软件，均为NVRE的财产，受知识产权法保护。未经NVRE事先书面同意，您不得使用、复制、修改或分发网站的任何内容。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>2.2. Trademarks:</b> All trademarks, service marks, and
                    logos displayed on the Website are the property of NVRE or
                    their respective owners. You are prohibited from using any
                    trademarks or logos without the prior written permission of
                    the owner.
                  </>
                ) : (
                  <>
                    <b>2.2. 商标：</b>
                    网站上显示的所有商标、服务标志和标志均为NVRE或其各自所有者的财产。未经所有者事先书面许可，禁止使用任何商标或标志。
                  </>
                )}
              </li>
            </ul>
          </li>
          <li>
            <h2>
              {lang
                ? "3. Disclaimer and Limitation of Liability"
                : "3. 免责声明和责任限制"}
            </h2>
            <ul>
              <li>
                {lang ? (
                  <>
                    <b>3.1. Website Content:</b> The information provided on the
                    Website is for general informational purposes only. While we
                    strive to ensure the accuracy and timeliness of the content,
                    we make no representations or warranties of any kind,
                    express or implied, about the completeness, accuracy,
                    reliability, suitability, or availability of the
                    information.
                  </>
                ) : (
                  <>
                    <b>3.1. 网站内容：</b>
                    本网站提供的信息仅供一般参考之用。虽然我们努力确保内容的准确性和及时性，但我们对信息的完整性、准确性、可靠性、适用性或可用性不作任何明示或暗示的陈述或保证。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>3.2. Third-Party Links:</b> The Website may contain links
                    to third-party websites. These links are provided for your
                    convenience, but we do not endorse or have control over the
                    content, privacy policies, or practices of these websites.
                    We are not responsible for any loss or damage incurred as a
                    result of your use of any third-party website.
                  </>
                ) : (
                  <>
                    <b>3.2. 第三方链接：</b>
                    本网站可能包含指向第三方网站的链接。这些链接是为您的方便而提供的，但我们不认可或控制这些网站的内容、隐私政策或做法。对于您使用任何第三方网站而造成的任何损失或损害，我们概不负责。
                  </>
                )}
              </li>
              <li>
                {lang ? (
                  <>
                    <b>3.3. Limitation of Liability:</b> To the fullest extent
                    permitted by law, NVRE and its affiliates, directors,
                    employees, or agents shall not be liable for any direct,
                    indirect, incidental, special, consequential, or exemplary
                    damages arising out of or in connection with your use of the
                    Website.
                  </>
                ) : (
                  <>
                    <b>3.3. 责任限制：</b>
                    在法律允许的最大范围内，NVRE及其附属公司、董事、员工或代理人对因您使用本网站而产生的或与之相关的任何直接、间接、附带、特殊、后果性或惩戒性损害不承担任何责任。
                  </>
                )}
              </li>
            </ul>
          </li>
          <li>
            <h2>4. {lang ? "Privacy Policy" : "隐私政策"}</h2>
            <p>
              {lang
                ? `Your use of the Website is subject to our Privacy Policy
              [hyperlink]. By using the Website, you consent to the collection,
              use, and disclosure of your information as described in the
              Privacy Policy.`
                : `您的使用受我们的隐私政策约束。通过使用本网站，您同意根据隐私政策收集、使用和披露您的信息。`}
            </p>
          </li>
          <li>
            <h2>5. {lang ? "Modifications to the Terms" : "条款修改"}</h2>
            <p>
              {lang
                ? `NVRE reserves the right to modify or update these Terms at any
              time without prior notice. It is your responsibility to review
              these Terms periodically for any changes. Your continued use of
              the Website after any modifications to the Terms constitutes your
              acceptance of the revised Terms.`
                : `NVRE保留随时修改或更新这些条款的权利，无需事先通知。您有责任定期查看这些条款以了解任何更改。您继续使用本网站，即使对这些条款进行了任何修改，也构成您对修订条款的接受。`}
            </p>
          </li>
          <li>
            <h2>
              6.{" "}
              {lang ? "Governing Law and Jurisdiction" : "管辖法律和司法管辖区"}
            </h2>
            <p>
              {lang
                ? `These Terms shall be governed by and construed in accordance with
              the laws of [Jurisdiction]. Any dispute arising out of or in
              connection with these Terms shall be subject to the exclusive
              jurisdiction of the courts located in [Jurisdiction].`
                : `这些条款应受并根据[司法管辖区]的法律解释和执行。任何因这些条款引起的争议应受[司法管辖区]法院的专属管辖。`}
            </p>
          </li>
          <li>
            <h2>{lang ? "Contact Us" : "联系我们"}</h2>
            <p>
              <>
                {lang
                  ? `If you have any questions, concerns, or feedback regarding these
                Terms, please contact us at `
                  : `如果您有任何疑问、关注或反馈，请通过以下联系方式联系我们：`}
              </>
              <a href="mailto:contact@nvre.co.uk">contact@nvre.co.uk</a>.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}
