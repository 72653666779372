export const preloadImage = (src: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = reject;
        img.src = src;
    });
};

export const preloadImagesIfNeeded = async () => {
    const driver = localStorage.getItem("driver");

    if (!driver) {
        try {
            await Promise.all([
                preloadImage(require("../../assets/media/developmentMaps/map.jpeg")),
                preloadImage(require("../../assets/media/developmentMaps/list.jpeg")),
            ]);
            console.log("Images preloaded successfully.");
        } catch (error) {
            console.error("Error preloading images:", error);
        }
    }
};