import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Partners from "./Partners/Partners";
import { NavLink } from "react-router-dom";
import Contact from "../Contact/Contact";
import styles from "./HomePage.module.scss";

import { ReactComponent as house } from "../../assets/media/icons/svg/building.svg";
import { ReactComponent as manage } from "../../assets/media/icons/svg/document.svg";
import { ReactComponent as loan } from "../../assets/media/icons/svg/bank.svg";
import { ReactComponent as lawyer } from "../../assets/media/icons/svg/law.svg";
import { ReactComponent as tax } from "../../assets/media/icons/svg/pound.svg";
import { ReactComponent as school } from "../../assets/media/icons/svg/school.svg";
import { HashLink } from "react-router-hash-link";
import {
  BeachIcon,
  InvestIcon,
  MagnifierIcon,
  RightArrowIcon,
  SofaIcon,
} from "../../assets/assetExport";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import {
  getAllDevelopments,
  getFilters,
  setFilters,
  FilterType,
  initialFilter,
  clearFilter,
  setSearchKeyword,
  getSearchKeyword,
  getKeywordFilteredFilteredDevelopmentIds,
} from "../../features/developments/developmentsSlice";
import MapView from "../Sale/Views/MapView";
import { getLayoutHeaderHeight } from "../../features/layouts/layoutsSlice";
import { getLang } from "../../features/app/UserRelatedSlice";
import {
  getIsMobile,
  getIsRealMobile,
  setShowFilterCarriedOverNotification,
  triggerMapUpdate,
} from "../../features/app/UIRelatedSlice";

const londonIllustration = require("../../assets/media/decoration/london-illustration.png");

export default function HomePage(props: any) {
  // SELECTORS //
  const lang = useSelector(getLang);
  const isRealMobile = useSelector(getIsRealMobile);
  const allDevelopments = useSelector(getAllDevelopments);
  const filter = useSelector(getFilters);
  const searchKeyword = useSelector(getSearchKeyword);
  const headerHeight = useSelector(getLayoutHeaderHeight);
  const filters = useSelector(getFilters);
  const keywordFilteredDevelopmentIds = useSelector(
    getKeywordFilteredFilteredDevelopmentIds
  );

  // TOOLS //
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const scrollToContact = location.state && location.state.scrollToContact;

  // STATES //
  const service = [
    { img: loan, en: "Mortgage", zh: "银行贷款" },
    { img: house, en: "Buy a Property", zh: "房源挑选" },
    { img: lawyer, en: "Legal Services", zh: "律师推荐" },
    { img: manage, en: "Property Management", zh: "租赁管理" },
    { img: tax, en: "Tax Planning", zh: "税务规划" },
    { img: school, en: "School", zh: "子女入学" },
  ];
  const [
    isMapViewContainerAboveBottomScreen,
    setIsMapViewContainerAboveBottomScreen,
  ] = useState(false);
  const [localFilters, setLocalFilters] = useState<FilterType>(filter);
  const [localSearchKeyword, setLocalSearchKeyword] =
    useState<string>(searchKeyword);

  // REFS //
  const targetRef = useRef<HTMLDivElement>(null);
  const mapViewContainerRef = useRef<HTMLDivElement>(null);

  // CALLBACKS AND FUNCTIONS //
  const handleAdvancedSearch = useCallback(() => {
    const shouldShowNotification =
      filters.price[0] !== 0 ||
      filters.price[1] !== 0 ||
      Object.values(filters.bedrooms).some((v) => v) ||
      localSearchKeyword !== "";

    if (shouldShowNotification) {
      dispatch(setFilters(localFilters));
      dispatch(setSearchKeyword(localSearchKeyword));
    }

    dispatch(setShowFilterCarriedOverNotification(shouldShowNotification));
    navigate(`/property?view=map`);
  }, [
    filters,
    localFilters,
    localSearchKeyword,
    dispatch,
    setShowFilterCarriedOverNotification,
    navigate,
  ]);

  const handleBrowseProperties = () => {
    dispatch(clearFilter());
    navigate("/property?view=map");
  };

  const handleUpdateFilter = useCallback(
    (e: React.FormEvent<HTMLFormElement>, newSearchKeyword?: string) => {
      e.preventDefault();
      if (!allDevelopments) return;

      dispatch(setFilters(localFilters));
      dispatch(setSearchKeyword(newSearchKeyword ?? localSearchKeyword));
      dispatch(triggerMapUpdate());
    },
    [localFilters, localSearchKeyword, dispatch, allDevelopments]
  );

  function updateFilterPrice(
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) {
    const value = Number(e.target.value);

    setLocalFilters((prevFilters: FilterType) => ({
      ...prevFilters,
      price: prevFilters.price.map((p, i) => (i === index ? value : p)) as [
        number,
        number
      ],
    }));
  }

  function updateFilterBedrooms(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalFilters((prevFilters: FilterType) => ({
      ...prevFilters,
      bedrooms: {
        ...prevFilters.bedrooms,
        [e.target.name]: e.target.checked,
      },
    }));
  }

  // COMPONENTS //
  function SearchButton() {
    return (
      <button
        className="btn btn-sm bg-main hover:bg-secondary-dark text-white"
        type="submit"
      >
        {lang ? "Search" : "筛选"}
      </button>
    );
  }

  function AdvancedSearchButton() {
    return (
      <NavLink
        to="/property?view=map"
        onClick={(e) => {
          e.preventDefault();
          handleAdvancedSearch();
        }}
      >
        <button
          type="button"
          className="btn btn-sm whitespace-nowrap bg-gray-100 hover:bg-gray-200 text-gray-500 font-normal"
        >
          {lang ? "Advanced Search" : "高级筛选"}
        </button>
      </NavLink>
    );
  }

  // EFFECTS //
  useEffect(() => {
    if (scrollToContact && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [scrollToContact]);

  useEffect(() => {
    dispatch(clearFilter());
    setLocalFilters(initialFilter);
    setLocalSearchKeyword("");
    dispatch(setSearchKeyword(""));
  }, [dispatch]);

  useEffect(() => {
    function handleScroll() {
      if (mapViewContainerRef.current) {
        const rect = mapViewContainerRef.current.getBoundingClientRect();
        const isAboveBottom = rect.bottom < window.innerHeight;
        setIsMapViewContainerAboveBottomScreen(isAboveBottom);
      }
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsMapViewContainerAboveBottomScreen]);

  return (
    <div className="text-gray-800" id="home-page">
      <section
        className="pb-20 flex flex-col items-center justify-center relative"
        style={{ minHeight: "calc(100vh - " + headerHeight + "px)" }}
      >
        {/* <p>{filteredDevelopmentIds && filteredDevelopmentIds.length}</p> */}
        <div
          className={`mx-auto md:mx-8 lg:mx-16 mt-10 xs:mt-20 max-w-[90vw] xs:max-w-full  px-3 sm:px-6 py-6 relative flex  flex-col items-start sm:items-center gap-2 `}
        >
          <p
            className={`text-gray-700 text-2xl font-poster2 ml-1 xs:ml-0 mb-10 xs:mb-2 ${
              isRealMobile ? "leading-relaxed" : ""
            }`}
          >
            <NavLink
              to="/property?view=map"
              onClick={(e) => {
                e.preventDefault();
                handleBrowseProperties();
              }}
              className="px-2 py-1 rounded-lg bg-tertiary-light/60 hover:bg-tertiary-light text-secondary-dark text-inherit-size font-poster2"
              style={{ textTransform: "none" }}
            >
              {lang ? "Browse properties" : "浏览全伦敦房源"}
            </NavLink>
            {<span>, </span>}
            {isRealMobile && (
              <>
                <br />
                <span>&nbsp;</span>
              </>
            )}
            <span>
              {lang ? (
                <span>
                  that fit your location and{" "}
                  {isRealMobile ? (
                    <>
                      <br />
                      <span>&nbsp;</span>
                    </>
                  ) : (
                    ""
                  )}
                  unique needs.
                </span>
              ) : (
                "筛选匹配以满足您的需求"
              )}
            </span>
          </p>
          {/* <div className={`${styles.filterContainer} px-10 relative flex  flex-col items-center gap-6 py-4 bg-secondary-light/10`}> */}
          {/* <img className="object-contain w-full grayscale-[0.6] absolute bottom-0 opacity-10 -z-10" src={londonIllustration} alt="illustration of london" /> */}
          <form
            className={`${styles.filters} bg-white ${
              isRealMobile ? "px-0 max-w-[95vw]" : "px-5 "
            } py-5 rounded-lg w-full`}
            onSubmit={(e) => handleUpdateFilter(e)}
          >
            {/* <div className={`${styles.filters} bg-white px-10 py-5 rounded-lg w-full`}> */}
            <div className={`${styles.search} w-full flex items-center gap-2`}>
              <label className="input input-sm max-w-[90vw] xs:max-w-full input-bordered border-gray-300 flex items-center gap-2 w-full bg-white">
                <MagnifierIcon width="15" height="15" />
                <input
                  className="input input-xs w-full bg-white text-base"
                  type="text"
                  placeholder={
                    lang
                      ? "Search with a keyword" +
                        (!isRealMobile ? ": e.g. OWO / Mayfair" : "")
                      : isRealMobile
                      ? "输入关键字"
                      : "输入关键字：例如 Knightsbridge Gate"
                  }
                  value={localSearchKeyword}
                  onChange={(e: any) => {
                    const newValue = e.target.value;
                    setLocalSearchKeyword(newValue);
                    // If the new value is empty, trigger the filter update
                    if (newValue === "") {
                      handleUpdateFilter(e, "");
                    }
                  }}
                />
                <p
                  className="hover:cursor-pointer p-2 opacity-30"
                  onClick={(e: any) => {
                    setLocalSearchKeyword("");
                    handleUpdateFilter(e, "");
                  }}
                >
                  ✕
                </p>
              </label>
              {!isRealMobile && (
                <>
                  <SearchButton />
                  <AdvancedSearchButton />
                </>
              )}
            </div>
            <div
              className={`${styles.price} flex gap-2 items-center  max-w-[90vw] xs:max-w-full `}
            >
              <p className="whitespace-nowrap">{lang ? "Price:" : "价格"}</p>
              <label className={`${styles.inputWithCornerLabel} w-full`}>
                <span>{lang ? "From" : "从"}</span>
                <select
                  className="select select-sm select-bordered w-full bg-white"
                  id="outlined-price-from"
                  value={localFilters.price[0] ? localFilters.price[0] : 0}
                  onChange={(e) => {
                    updateFilterPrice(e, 0);
                  }}
                >
                  <option value={0}>{lang ? "No Min" : "无最低"}</option>
                  {[
                    200000,
                    ...Array.from({ length: 32 }, (_, i) => 225000 + i * 25000),
                    ...Array.from(
                      { length: 3 },
                      (_, i) => 1025000 + i * 250000
                    ),
                    2000000,
                    2500000,
                    ...Array.from(
                      { length: 7 },
                      (_, i) => 3000000 + i * 1000000
                    ),
                    10000000,
                    15000000,
                    20000000,
                    25000000,
                    50000000,
                    75000000,
                    100000000,
                  ].map((price) => (
                    <option key={price} value={price}>
                      £{price.toLocaleString()}
                    </option>
                  ))}
                </select>
              </label>

              <RightArrowIcon width="30" height="30" />
              <label
                className={`${styles.inputWithCornerLabel} w-full  max-w-[90vw] xs:max-w-full `}
              >
                <span>{lang ? "To" : "至"}</span>
                <select
                  className={`select select-sm select-bordered w-full bg-white`}
                  id="outlined-price-to"
                  value={
                    localFilters.price[1] ? localFilters.price[1] : "noMax"
                  }
                  onChange={(e) => {
                    updateFilterPrice(e, 1);
                  }}
                >
                  <option value={0}>{lang ? "No Max" : "无最高"}</option>
                  {[
                    200000,
                    ...Array.from({ length: 32 }, (_, i) => 225000 + i * 25000),
                    ...Array.from(
                      { length: 3 },
                      (_, i) => 1025000 + i * 250000
                    ),
                    2000000,
                    2500000,
                    ...Array.from(
                      { length: 7 },
                      (_, i) => 3000000 + i * 1000000
                    ),
                    10000000,
                    15000000,
                    20000000,
                    25000000,
                    50000000,
                    75000000,
                    100000000,
                  ]
                    .filter((price) => price > localFilters.price[0])
                    .map((price) => (
                      <option key={price} value={price}>
                        £{price.toLocaleString()}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <div
              className={`${styles.bedrooms} flex gap-2 xs:items-center flex-col items-start xs:flex-row`}
            >
              <p className="whitespace-nowrap">{lang ? "Bedrooms:" : "户型"}</p>
              {localFilters.bedrooms &&
                Object.keys(localFilters.bedrooms).length > 0 && (
                  <div className={`${styles.checkboxesContainer} `}>
                    <label>
                      <input
                        type="checkbox"
                        checked={localFilters.bedrooms["bed0"]}
                        name="bed0"
                        onChange={updateFilterBedrooms}
                      />
                      <span>{lang ? "Studio" : "开间"}</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={localFilters.bedrooms["bed1"]}
                        name="bed1"
                        onChange={updateFilterBedrooms}
                      />
                      <span>{lang ? "1" : "一室"}</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={localFilters.bedrooms["bed2"]}
                        name="bed2"
                        onChange={updateFilterBedrooms}
                      />
                      <span>{lang ? "2" : "两室"}</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={localFilters.bedrooms["bed3"]}
                        name="bed3"
                        onChange={updateFilterBedrooms}
                      />
                      <span>{lang ? "3" : "三室"}</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={localFilters.bedrooms["bed4"]}
                        name="bed4"
                        onChange={updateFilterBedrooms}
                      />
                      <span>{lang ? "4" : "四室"}</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={localFilters.bedrooms["bed5"]}
                        name="bed5"
                        onChange={updateFilterBedrooms}
                      />
                      <span className="text-nowrap">
                        {lang ? "5+" : "五室"}
                      </span>
                    </label>
                  </div>
                )}
            </div>
            {isRealMobile && (
              <div className="justify-center mt-2 flex gap-2">
                <SearchButton />
                <AdvancedSearchButton />
              </div>
            )}
          </form>
        </div>
        {
          // {filteredDevelopmentIds &&
          //   Object.keys(filteredDevelopmentIds).length > 0 && (
          <div className="w-full max-w-[1200px] p-6 relative">
            <p
              className="pb-2 pl-6"
              style={{
                color: "#d0d0d0",
                textShadow:
                  "0px 0.5px 0px rgba(255,255,255,0.3), 0px -0.5px 0px rgba(0,0,0,.3)",
              }}
            >
              {keywordFilteredDevelopmentIds && (
                <>
                  {keywordFilteredDevelopmentIds.length}{" "}
                  {lang
                    ? `developemnt${
                        keywordFilteredDevelopmentIds.length > 1 ? "s" : ""
                      }`
                    : "项目"}
                </>
              )}
            </p>
            <div
              className="home-page-container rounded-2xl border-4 border-white"
              style={{
                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                WebkitBoxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                height: "calc(100vh - " + headerHeight + "px)",
              }}
              ref={mapViewContainerRef}
            >
              {/* <TubeView zoomed={true} /> */}
              <MapView
                onHomePage={true}
                isVisible={true}
                rounded={true}
                isMapViewContainerAboveBottomScreen={
                  isMapViewContainerAboveBottomScreen
                }
              />
              {/* <img src={test} /> */}
            </div>
          </div>
        }
        <div className="max-w-[500px] my-40 flex flex-col items-center relative z-10">
          <p className="font-poster text-4xl sm:text-6xl opacity-15 flex flex-col items-center gap-5">
            {lang ? (
              <>
                <span className="">Elegant Living, </span>
                <span className="">Confident Investing</span>
              </>
            ) : (
              <span className="font-poster-cn text-shadow-inner">
                优雅生活，自信投资
              </span>
            )}
          </p>
          <div className="pt-10 pb-20">
            <LongSlogan isHome={true} />
          </div>
          <div
            className={`flex ${
              lang ? "flex-col" : "flex-row"
            } xs:flex-row gap-2 tracking-wider`}
          >
            <NavLink
              className="font-normal font-poster2 btn px-6 bg-main hover:bg-secondary-dark text-white rounded-full"
              to="/property?view=list"
            >
              {lang ? "BROWSE PROPERTIES" : "浏览房产"}
            </NavLink>
            <HashLink
              className="font-medium font-poster2  btn px-6 bg-transparent border border-main hover:bg-transparent text-secondary-dark rounded-full"
              to="/#contact-us"
            >
              {lang ? "CONTACT US" : "联系我们"}
            </HashLink>
          </div>
        </div>
        <img
          className="opacity-10 grayscale-[0.6] absolute bottom-6 right-0 w-screen"
          src={londonIllustration}
          alt="london illustartion art background"
        />
        {/* <img className="opacity-30 absolute bottom-[15%] right-[5%] w-[90%] object-cover" src={londonIllustration} alt="london illustartion art background" /> */}
      </section>
      <main className="">
        <div className="bg-gray-50/20 px-10 xs:px-20 sm:px-32 py-20 pt-40 flex flex-col gap-10">
          {/* <div className="bg-gray-100 px-32 py-20 flex flex-col gap-10"> */}
          <h2 className="text-secondary-dark">
            {lang ? "About NVRE" : "关于 NVRE"}
          </h2>
          <p className="leading-loose text-lg opacity-80">
            {lang
              ? `NVRE is a top-tier real estate business consulting firm based in central London, certified by the UK's foremost real estate regulator - "ARLA Propertymark". Our team comprises Cambridge University graduates with over 15 years of senior management and professional experience in real estate and asset management.`
              : `NVRE 是一家总部位于英国伦敦的高端地产咨询公司，受英国权威房产监管机构ARLA Propertymark认证，为高净值客户及家族企业提供专业高效的地产交易及租赁管理服务。我们的创始团队成员均毕业于剑桥大学，在英国的地产置业领域拥有多年的管理经验。专业性是广大客户选择我们的首要因素。`}
          </p>
          <NavLink
            className="button-learnmore text-lg opacity-80  text-secondary-light"
            to="/about"
          >
            {lang ? "Learn More" : "了解更多"}
          </NavLink>
        </div>
        <div className="px-10 xs:px-20 sm:px-32 py-20">
          <Services service={service} />
        </div>
        {/* <Insights /> */}
        <Partners />

        <div
          id="front-contact-us"
          className="px-10 xs:px-20 sm:px-32 pt-20 bg-tertiary-light/30"
          ref={targetRef}
        >
          <Contact />
        </div>
      </main>
    </div>
  );
}

function Services(props: any) {
  const lang = useSelector(getLang);

  return (
    <section className="mb-20">
      <div className="relative">
        <h2 className="">{lang ? "One-Stop Shop Services" : "一站式"}</h2>
        <p className={`font-poster${lang ? "" : "-cn"} desc`}>
          {lang ? "Everything You Need" : "VIP 服务"}
        </p>
      </div>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 sm:gap-6 mt-16`}
      >
        {props.service.map((item: any, index: any) => {
          return (
            <div
              className="flex gap-4 items-center"
              key={`service-${item}-${index}`}
            >
              <div className="p-3 bg-tertiary-light/60 rounded-xl">
                <item.img width="30" height="30" />
              </div>
              <p className="text-xl  opacity-90">{lang ? item.en : item.zh}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export function LongSlogan(props: any) {
  const lang = useSelector(getLang);
  const isRealMobile = useSelector(getIsRealMobile);
  const isMobile = useSelector(getIsMobile);
  return (
    <p className=" text-center leading-loose text-lg text-secondary-dark">
      {lang ? (
        <p className="font-poster2">
          <span className="sm:inline-flex sm:items-center sm:whitespace-nowrap">
            Whether it is a&nbsp;
            {isMobile && props.isHome && <br />}
            <span className="inline-flex mx-1 bg-tertiary-light/50 shadow-xl border-tertiary-light font-normal px-2 rounded-md gap-2 items-center">
              <SofaIcon width="18" height="18" />
              primary residence
            </span>
            ,{isMobile && props.isHome && <br />}
            <span className="inline-flex mx-1 bg-tertiary-light/50 shadow-xl border-tertiary-light font-normal px-2 rounded-md gap-2 items-center">
              <BeachIcon width="18" height="18" />
              holiday home
            </span>
            ,&nbsp;or&nbsp;
            {isMobile && props.isHome && <br />}
            <span className="inline-flex mx-1 bg-tertiary-light/50 shadow-xl border-tertiary-light font-normal px-2 rounded-md gap-2 items-center">
              <InvestIcon width="18" height="18" />
              investment
            </span>
            ,
            {/* <span className="inline-flex mx-1 border-gray-200 border shadow-xl font-normal px-2 rounded-md gap-2 items-center"><SofaIcon width="18" height="18" />primary residence</span>,
                                    <span className="inline-flex mx-1 border-gray-200 border shadow-xl font-normal px-2 rounded-md gap-2 items-center"><BeachIcon width="18" height="18" />holiday home</span>,&nbsp;or&nbsp;
                                    <span className="inline-flex mx-1 border-gray-200 border shadow-xl font-normal px-2 rounded-md gap-2 items-center"><InvestIcon width="18" height="18" />investment</span>, */}
          </span>
          {
            <>
              <br />
            </>
          }
          <span>
            we are here to help you {isRealMobile && <br />}to find the right
            property {isRealMobile && <br />}in the right location.
          </span>
        </p>
      ) : (
        <p>
          不论您是自住还是投资房产，我们都会尽专业所能，
          <br />
          用心帮助您和家人找到合适的项目和理想的地点。
        </p>
      )}
    </p>
  );
}
