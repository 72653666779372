import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./YieldPage.module.scss";
import { generatePDFDocumentFromRef } from "../../../util/PDFRelated/generatePDF";
import { getIsMobile } from "../../../features/app/UIRelatedSlice";
import { getIsRealMobile } from "../../../features/app/UIRelatedSlice";
import { useSelector } from "react-redux";
import { getLang } from "../../../features/app/UserRelatedSlice";

export default function RentalYieldPage(props: any) {
  const [inputs, setInputs] = useState({
    propertyPrice: "",
    stampDuty: "0",
    otherPurchasingCosts: "0",
    rent: "",
    otherAdminCosts: "",
    growth: "5",
  });
  const tableRef = useRef(null);

  const [calculated, setCalculated] = useState({
    totalPurchasingPrice: 0,
    monthlyIncome: 0,
    annualRent: 0, // 年租金
    annualNetCashFlowReturn: 0, // 年净现金流回报
    annualPropertyAppreciationReturn: 0, // 每年房产增值
    annualTotalInvestmentReturn: 0, // 年投资回报总计
    annualRentToSaleRatio: 0, // 年租售比
    annualInvestmentReturnRate: 0, // 年投资回报率
    annualComprehensiveInvestmentReturnRate: 0, // 年综合投资回报率
  });

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const isMobile = useSelector(getIsMobile);
  const lang = useSelector(getLang);
  const isRealMobile = useSelector(getIsRealMobile);

  function handleDownloadPDF() {
    setIsGeneratingPDF(true);
  }

  useEffect(() => {
    (async () => {
      if (isGeneratingPDF && tableRef.current) {
        const doc = await generatePDFDocumentFromRef(
          tableRef.current,
          props.page === "tool"
        );
        if (doc) {
          if (!isRealMobile) {
            doc.save(`NVRE Rental Yield Analysis.pdf`);
          } else {
            const pdfBlob = doc.output("blob");
            const link = document.createElement("a");
            link.href = URL.createObjectURL(pdfBlob);
            link.download = "NVRE Rental Yield Analysis.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          setIsGeneratingPDF(false);
        }
      }
    })();
  }, [isGeneratingPDF]);

  useEffect(() => {
    const totalPurchasingPrice =
      parseFloat(inputs.propertyPrice) +
      parseFloat(inputs.stampDuty) +
      parseFloat(inputs.otherPurchasingCosts);
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      totalPurchasingPrice,
    }));
  }, [inputs.propertyPrice, inputs.stampDuty, inputs.otherPurchasingCosts]);

  useEffect(() => {
    const monthlyIncome =
      parseFloat(inputs.rent) - parseFloat(inputs.otherAdminCosts);
    const annualNetCashFlowReturn = monthlyIncome * 12;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      monthlyIncome,
      annualNetCashFlowReturn,
    }));
  }, [inputs.rent, inputs.otherAdminCosts]);

  useEffect(() => {
    const annualRent = parseFloat(inputs.rent) * 12;
    setCalculated((prevCalculated) => ({ ...prevCalculated, annualRent }));
  }, [inputs.rent]);

  useEffect(() => {
    const propertyPrice = parseFloat(inputs.propertyPrice);
    const annualPropertyAppreciationReturn =
      (propertyPrice * parseFloat(inputs.growth)) / 100;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualPropertyAppreciationReturn,
    }));
  }, [inputs.propertyPrice, inputs.growth]);

  useEffect(() => {
    const totalPurchasingPrice = parseFloat(inputs.propertyPrice);
    const annualRent = parseFloat(inputs.rent) * 12;
    const annualRentToSaleRatio = annualRent / totalPurchasingPrice;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualRentToSaleRatio,
    }));
  }, [inputs.propertyPrice, inputs.rent]);

  useEffect(() => {
    const totalPurchasingPrice =
      parseFloat(inputs.propertyPrice) +
      parseFloat(inputs.stampDuty) +
      parseFloat(inputs.otherPurchasingCosts);
    const monthlyIncome =
      parseFloat(inputs.rent) - parseFloat(inputs.otherAdminCosts);
    const annualNetCashFlowReturn = monthlyIncome * 12;
    const annualInvestmentReturnRate =
      annualNetCashFlowReturn / totalPurchasingPrice;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualInvestmentReturnRate,
    }));
  }, [
    inputs.propertyPrice,
    inputs.stampDuty,
    inputs.otherPurchasingCosts,
    inputs.rent,
    inputs.otherAdminCosts,
  ]);

  useEffect(() => {
    const totalPurchasingPrice =
      parseFloat(inputs.propertyPrice) +
      parseFloat(inputs.stampDuty) +
      parseFloat(inputs.otherPurchasingCosts);
    const monthlyIncome =
      parseFloat(inputs.rent) - parseFloat(inputs.otherAdminCosts);
    const annualNetCashFlowReturn = monthlyIncome * 12;
    const propertyPrice = parseFloat(inputs.propertyPrice);
    const annualPropertyAppreciationReturn =
      (propertyPrice * parseFloat(inputs.growth)) / 100;
    const annualTotalInvestmentReturn =
      annualNetCashFlowReturn + annualPropertyAppreciationReturn;
    const annualComprehensiveInvestmentReturnRate =
      annualTotalInvestmentReturn / totalPurchasingPrice;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualTotalInvestmentReturn,
      annualComprehensiveInvestmentReturnRate,
    }));
  }, [
    inputs.propertyPrice,
    inputs.stampDuty,
    inputs.otherPurchasingCosts,
    inputs.rent,
    inputs.otherAdminCosts,
    inputs.growth,
  ]);

  useEffect(() => {
    const elementsToHide = lang
      ? document.querySelectorAll("[language=zh]")
      : document.querySelectorAll("[language=en]");
    const elementsToShow = lang
      ? document.querySelectorAll("[language=en]")
      : document.querySelectorAll("[language=zh]");
    elementsToHide.forEach((element: any) => {
      element.style.display = "none";
    });
    elementsToShow.forEach((element: any) => {
      element.style.display = "block";
    });
  }, [lang]);

  return (
    <div
      className={`content-inner ${
        props.page === "tool" ? "" : "items-center"
      } gap-12 mx-auto ${
        props.page === "tool" ? "" : "p-6 sm:p-16 mb-[240px]"
      }  ${styles.rentalYieldContainer}`}
    >
      <h1
        className={`${
          props.page === "tool" ? "" : ""
        } font-semibold mb-8 text-4xl`}
      >
        {lang
          ? "Property Investment Rent-to-Price Ratio Analysis"
          : "房产投资租售比分析"}
      </h1>
      <div className="font-main-sans font-light text-base">
        {lang ? (
          <p>
            This form helps you calculate the rental yield and investment return
            for a property. <br />
            Fill in the required fields to get detailed insights into your
            potential earnings and costs.
          </p>
        ) : (
          <p>
            此表格帮助您计算房产的租金收益和投资回报。
            <br />
            填写所需字段以获取有关您潜在收入和成本的详细信息。
          </p>
        )}
      </div>

      <form
        ref={tableRef}
        id="to-print-rental-yield"
        className={`${props.page === "tool" ? "tool" : ""} ${
          styles.tableContainer
        }  pb-8 max-w-[700px] mt-20 flex flex-col gap-16 font-sans  ${
          props.page === "tool" ? "mx-0" : ""
        }`}
      >
        {/* <form ref={tableRef} id="to-print-rental-yield" className={`${styles.tableContainer}  pb-8 max-w-[700px] mt-20 flex flex-col gap-16 font-sans  ${props.page === "tool" ? "mx-0" : ""}`} onChange={() => { setPdfUrl(""); }}> */}
        <p id="rental-yield-title" className="hidden">
          {lang
            ? "Property Investment Rent-to-Price Ratio Analysis"
            : "房产投资租售比分析"}
        </p>
        <div id="margin-top">
          <h2 className="">{lang ? "Total Purchase Costs" : "购房总费用"}</h2>
          <div className={styles.tableGroupContainer}>
            <label>{lang ? "Property Purchase Price" : "房产购买价格"}</label>
            <div>
              £
              <FormInput
                lang={lang}
                inputValue={inputs.propertyPrice}
                attribute="propertyPrice"
                setInputs={setInputs}
                isGeneratingPDF={isGeneratingPDF}
              />
            </div>
            <label>
              <span>{lang ? "Stamp Duty " : "房产购买印花税 "}</span>
              <span>{lang ? "[Optional]" : "[选填]"}</span>
            </label>
            <div>
              £
              <FormInput
                lang={lang}
                inputValue={inputs.stampDuty}
                attribute="stampDuty"
                setInputs={setInputs}
                isGeneratingPDF={isGeneratingPDF}
                canIgnore
              />
            </div>
            <label>
              <span>{lang ? "Other Costs" : "其他费用"}</span>
              {((isMobile && lang) || isRealMobile) && <br />}
              <span>
                {lang
                  ? " (e.g., legal fees, furniture costs) [Optional]"
                  : "（如律师费，家具费等）[选填]"}
              </span>
            </label>
            <div>
              £
              <FormInput
                lang={lang}
                inputValue={inputs.otherPurchasingCosts}
                attribute="otherPurchasingCosts"
                setInputs={setInputs}
                isGeneratingPDF={isGeneratingPDF}
                canIgnore
              />
            </div>
            <label className="font-semibold">
              {lang ? "Total Purchase Costs" : "购房总费用"}
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.totalPurchasingPrice}
              />
            </div>
          </div>
        </div>
        <div>
          <h2 className="">
            {lang ? "Monthly Net Cash Flow Analysis" : "月净现金流分析"}
          </h2>
          <div className={styles.tableGroupContainer}>
            <label>{lang ? "Monthly Rent" : "月租金"}</label>
            <div>
              £
              <FormInput
                lang={lang}
                inputValue={inputs.rent}
                attribute="rent"
                setInputs={setInputs}
                isGeneratingPDF={isGeneratingPDF}
              />
            </div>
            <label>
              <span>{lang ? "Monthly Expenses " : "月支出额"}</span>
              {((isMobile && lang) || isRealMobile) && <br />}
              <span>
                {lang
                  ? "(e.g., service charges, rental management fees)"
                  : "（如物业费，租赁管理费等）"}
              </span>
            </label>
            <div>
              £
              <FormInput
                lang={lang}
                inputValue={inputs.otherAdminCosts}
                attribute="otherAdminCosts"
                setInputs={setInputs}
                isGeneratingPDF={isGeneratingPDF}
              />
            </div>
            <label className="font-semibold">
              <span>{lang ? "Monthly Net Cash Flow " : "月净现金流"}</span>
              {((isMobile && lang) || isRealMobile) && <br />}
              <span>
                {lang
                  ? "(Rental Income - Monthly Expenses)"
                  : "（租金收入-月支出额）"}
              </span>
            </label>
            <div>
              <CalculatedValue lang={lang} value={calculated.monthlyIncome} />
            </div>
          </div>
        </div>
        <div id="yield-results">
          <h2 className="">
            {lang ? "Annual Investment Return Analysis" : "年投资回报分析"}
          </h2>
          <div className={`${styles.tableGroupContainer} font-semibold`}>
            <label className="flex flex-col gap-1">
              <span>{lang ? "Annual Rent" : "年租金"}</span>
              <span className={styles.span}>
                {lang ? "(Monthly Rent X 12)" : "(月租金X12)"}
              </span>
            </label>
            <div>
              <CalculatedValue lang={lang} value={calculated.annualRent} />
            </div>
            <label className="flex flex-col gap-1">
              <span>
                {lang ? "Annual Net Cash Flow Return" : "年净现金流回报"}
              </span>
              <span className={styles.span}>
                {lang ? "(Monthly Net Cash Flow X 12)" : "(月净现金流X12)"}
              </span>
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.annualNetCashFlowReturn}
              />
            </div>
            <label className="flex gap-1 align-top">
              <span>
                {lang ? "Annual Property Appreciation" : "每年房产增值"}&nbsp;
              </span>
              <FormInput
                lang={lang}
                inputValue={inputs.growth}
                setInputs={setInputs}
                attribute="growth"
                isGeneratingPDF={isGeneratingPDF}
                special
                width
              />
              %<span></span>
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.annualPropertyAppreciationReturn}
              />
            </div>
            <label className="flex flex-col gap-1">
              <span>
                {lang ? "Total Annual Investment Return" : "年投资回报总计"}
              </span>
              <span className={styles.span}>
                {lang
                  ? "[Annual Net Cash Flow Return + Annual Property Appreciation]"
                  : "[年净现金流回报+年房产增值]"}
              </span>
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.annualTotalInvestmentReturn}
              />
            </div>
            <label className="flex flex-col gap-1">
              <span>{lang ? "Annual Rent-to-Price Ratio" : "年租售比"}</span>
              <span className={styles.span}>
                {lang
                  ? "[Annual Rent / Property Purchase Price]"
                  : "[年租金/房产购买价格]"}
              </span>
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.annualRentToSaleRatio}
                percentage
              />
            </div>
            <label className="flex flex-col gap-1">
              <span>
                {lang ? "Annual Investment Return Rate" : "年投资回报率"}
              </span>
              <span className={styles.span}>
                {lang
                  ? "[Annual Net Cash Flow Return / Total Purchase Costs]"
                  : "[年净现金流回报/购房总费用]"}
              </span>
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.annualInvestmentReturnRate}
                percentage
              />
            </div>
            <label className="flex flex-col gap-1">
              <span>
                {lang
                  ? "Annual Comprehensive Investment Return Rate"
                  : "年综合投资回报率"}
              </span>
              <span className={styles.span}>
                {lang
                  ? "[Total Annual Investment Return / Total Purchase Costs]"
                  : "[年投资回报总计/购房总费用]"}
              </span>
            </label>
            <div>
              <CalculatedValue
                lang={lang}
                value={calculated.annualComprehensiveInvestmentReturnRate}
                percentage
              />
            </div>
          </div>
        </div>
      </form>
      <div className="flex gap-5 w-auto mt-20">
        <button
          className="btn btn-sm bg-gray-200  text-gray-700"
          onClick={handleDownloadPDF}
        >
          {isGeneratingPDF ? (
            <span>{lang ? "Downloading PDF..." : "PDF 下载中..."}</span>
          ) : (
            <p>
              <span>{lang ? "Download PDF" : "下载 PDF"}</span>
            </p>
          )}
        </button>
      </div>

      {/* {(isRealMobile && pdfUrl) &&
                <div className="pdf-download-link">
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download="NVRE Rental Yield Analysis.pdf">
                        {lang ? "Open PDF" : "打开 PDF"}
                    </a>
                </div >
            } */}
      <div className="opacity-50 mt-20 text-sm">
        <p>
          {lang
            ? "The amounts in the form are for reference only and are subject to actual occurrence;"
            : "表中的金额以实际发生为准，以上数据仅供参考；"}
        </p>
        <p className="mt-2">
          {lang
            ? "If you reside continuously for 183 days after coming to the UK, you may be eligible for a 2% tax refund for overseas buyers with a visa that allows continuous residence."
            : "来英后连续居住183天可以退2%海外买家税，但需要购房者有可以连续居住的签证。"}
        </p>
      </div>
    </div>
  );
}

function FormInput({
  inputValue,
  setInputs,
  attribute,
  isGeneratingPDF,
  special = false,
  width = false,
  canIgnore = false,
  lang,
}: any) {
  const [inputValueDisplay, setInputValueDisplay] = useState(inputValue);

  useEffect(() => {
    const result = parseFloat(inputValue).toLocaleString();
    setInputValueDisplay(result === "NaN" ? "" : result);
  }, [inputValue]);

  return (
    <>
      {isGeneratingPDF ? (
        <span>{inputValueDisplay}</span>
      ) : (
        <input
          className={`${width ? "w-5" : "w-24"} bg-white h-7`}
          value={inputValueDisplay}
          placeholder={lang ? " Enter value" : " 请输入数值"}
          type="text"
          onChange={(e) => {
            const value = e.target.value.replace(/[^\d.]/g, "");
            if (value === "" || !isNaN(parseFloat(value))) {
              setInputs((prevInputs: any) => ({
                ...prevInputs,
                [attribute]: value,
              }));
            }
          }}
        />
      )}
      {!special && (
        <button
          className="underline underline-offset-4 text-xs text-main"
          style={{ display: isGeneratingPDF ? "none" : "inline-block" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setInputs((prevInputs: any) => ({
              ...prevInputs,
              [attribute]: canIgnore ? 0 : "",
            }));
          }}
        >
          {lang ? "clear" : "清除"}
        </button>
      )}
    </>
  );
}

function CalculatedValue({ value, percentage = false, lang }: any) {
  return (
    <p className="text-base font-sans ">
      {!isNaN(value) ? (
        <span className="">
          {percentage
            ? (value * 100).toFixed(1) + "%"
            : "£" + value.toLocaleString()}
        </span>
      ) : (
        <span className="bg-gray-200 px-3 py-1 rounded-full opacity-30 text-sm font-normal">
          {lang ? "Please complete all fields" : "请完成表中信息"}
        </span>
      )}
    </p>
  );
}
