import React, { useContext, useEffect, useRef, useState } from "react";
import { generatePDFDocumentFromRef } from "../../../util/PDFRelated/generatePDF";
import { getLang } from "../../../features/app/UserRelatedSlice";
import { useSelector } from "react-redux";
import { getIsRealMobile } from "../../../features/app/UIRelatedSlice";

type PropertyTypeKey = "flat" | "terraced_house" | "semi-detached_house" | "detached_house";


const PropertyType = {
    "flat": "公寓",
    "terraced_house": "联排别墅",
    "semi-detached_house": "半独立式房屋",
    "detached_house": "独立式房屋"
};


export default function RentEstimatePage(props: any) {
    const [inputs, setInputs] = useState({
        postcode: "W1J 8DZ",
        bedrooms: 1,
        type: "flat",
        // points: 20
    });

    const [result, setResult] = useState<any>({ status: "null" });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    // const [pdfUrl, setPdfUrl] = useState("");
    const lang = useSelector(getLang);
    const tableRef = useRef(null);
    const isRealMobile = useSelector(getIsRealMobile);

    async function handleEstimateRent(e: any) {
        e.preventDefault();
        setError("");
        setLoading(true);
        if (!inputs.postcode.replaceAll(" ", "")) return;
        try {
            const response15 = await fetch(`https://api.propertydata.co.uk/rents?key=0YU2HWR2QR&postcode=${inputs.postcode.replaceAll(" ", "")}&bedrooms=${inputs.bedrooms}&type=${inputs.type}&points=15`);
            const response100 = await fetch(`https://api.propertydata.co.uk/rents?key=0YU2HWR2QR&postcode=${inputs.postcode.replaceAll(" ", "")}&bedrooms=${inputs.bedrooms}&type=${inputs.type}&points=100`);
            // const response = await fetch(`https://api.propertydata.co.uk/rents?key=${process.env.PROPERTYDATA_KEY}&postcode=${inputs.postcode.replaceAll(" ", "")}&bedrooms=${inputs.bedrooms}&type=${inputs.type}&points=${inputs.points}`);
            const result = [await response15.json(), await response100.json()];
            // Math.min(...result.data)
            if (result[0].status === "success" && result[1].status === "success") {
                const data = [result[0].data.long_let.average, result[1].data.long_let.average];
                setResult({
                    status: "success",
                    data: [Math.min(...data), Math.max(...data)]
                });
            } else if (result[0].status === "error") {
                setError(result[0].message);
            } else if (result[1].status === "error") {
                setError(result[1].message);
            }
        } catch (error) {
            console.error("Error fetching rental estimate:", error);
            setError("There was an error fetching rental estimate, please try again later.");
        } finally {
            setLoading(false);
        }
    }

    function _getChineseType(input: PropertyTypeKey) {
        // This function now correctly maps the English property type to Chinese
        return PropertyType[input];
    }

    function handleDownloadPDF() {
        setIsGeneratingPDF(true);
    }

    useEffect(() => {
        (async () => {

            if (isGeneratingPDF && tableRef.current) {
                try {

                    const doc = await generatePDFDocumentFromRef(tableRef.current, props.page === "tool");
                    if (doc) {
                        if (!isRealMobile) {
                            doc.save(`NVRE Rental Income Estimation.pdf`);
                        } else {
                            const pdfBlob = doc.output('blob');
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(pdfBlob);
                            link.download = 'NVRE Rental Income Estimation.pdf';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                        setIsGeneratingPDF(false);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsGeneratingPDF(false);
                }

            }
        })();
    }, [isGeneratingPDF]);



    return (

        <div className={`flex flex-col items-center`}>
            {/* <div className={`flex flex-col items-center ${isEnglish ? " isEnglish" : "isChinese"}`}> */}

            <div id="to-print-rent-estimate" ref={tableRef} className={`content-inner ${props.page === "tool" ? "tool w-full" : "w-full sm:w-auto p-10 sm:p-16 sm:max-w-[800px] sm:min-w-[600px]"}  `}>
                <form
                    onSubmit={handleEstimateRent}
                    onChange={() => { setError(""); setResult({ status: "null" }); }}
                    // onChange={() => { setError(""); setResult({ status: "null" }); setPdfUrl(""); }}
                    className="flex flex-col gap-10 text-secondary-dark"
                >
                    <h1 id="service-title" className="mb-1 sm:mb-10 font-semibold text-4xl">{lang ? "Estimate Rental Income" : "估算租金收入"}</h1>
                    <label id="margin-top" className="flex sm:items-center gap-4 flex-col sm:flex-row return-flex-row">
                        <span className="w-[10rem] font-semibold text-gray-700">{lang ? "Postcode:" : "邮政编码"}</span>
                        <input
                            className="input input-bordered sm:w-full sm:max-w-xs bg-transparent"
                            type="text"
                            value={inputs.postcode}
                            placeholder="W1J 8DZ"
                            onChange={(e) => setInputs({ ...inputs, postcode: e.target.value })}
                        />
                    </label>
                    <div className="flex gap-4 flex-col sm:flex-row return-flex-row">
                        <span className="w-[10rem] font-semibold text-gray-700">{lang ? "Bedrooms:" : "卧室"}</span>
                        <div className="flex gap-4">
                            {[0, 1, 2, 3, 4, 5].map((bedroom) => (
                                <label key={bedroom} className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm"
                                        type="radio"
                                        name="bedrooms"
                                        value={bedroom}
                                        checked={inputs.bedrooms === bedroom}
                                        onChange={() => setInputs({ ...inputs, bedrooms: bedroom })}
                                    />
                                    <span>{bedroom}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className={`flex  gap-4 return-flex-row`}>
                        <span className="w-[10rem] font-semibold text-gray-700">{lang ? "Property Type:" : "物业类型:"}</span>
                        <div id="property-type-list" className={`flex flex-col gap-4 ${props.page === "tool" ? "" : "sm:flex-row"}`}>

                            {Object.keys(PropertyType).map((type) => (
                                <label key={type} className="flex items-center gap-1">
                                    <input
                                        className="radio radio-sm"
                                        type="radio"
                                        name="type"
                                        value={type}
                                        checked={inputs.type === type}
                                        onChange={() => setInputs({ ...inputs, type: type })}
                                    />
                                    <span>{lang ? type.replace(/_/g, ' ') : _getChineseType(type as PropertyTypeKey).replace(/_/g, ' ')}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <button className={`btn pt-0 bg-gray-200 text-gray-800 ${props.page === "tool" ? "mt-10" : "mt-0"}`} type="submit" disabled={!inputs.postcode.replaceAll(" ", "")}>
                        {loading ?
                            (lang ? "Estimating..." : "为您估算中...")
                            : (lang ? "Estimate" : "估算")
                        }
                    </button>
                </form>
                <div className="mt-16 flex flex-col">
                    {error && <p className="font-semibold mb-4 text-main">{error}</p>}
                    {result && result.status === "success" && result.data &&
                        <>
                            <h3 className="text-main">{lang ? "Results" : "结果"}</h3>

                            <h3 className="font-semibold text-xl my-4 text-main">
                                {lang ? (
                                    <>
                                        <span className="font-normal">Estimated Rent:&nbsp;</span>
                                        £{formatMonthlyPrice(result.data[0])} <span>to</span> £{formatMonthlyPrice(result.data[1])} <span>per month</span>
                                    </>
                                ) : (
                                    <>
                                        <span>估计租金: </span>
                                        £{formatMonthlyPrice(result.data[0])} <span>至</span> £{formatMonthlyPrice(result.data[1])} <span>每月</span>
                                    </>
                                )}
                            </h3>
                            <p className="font-sans text-base text-secondary-dark">
                                {lang ? (
                                    <span>A {inputs.type.replace(/_/g, ' ')} at {inputs.postcode} with {inputs.bedrooms} bedroom{inputs.bedrooms > 1 && "s"} has an average rent of £{result.data[0].toLocaleString()} to £{result.data[1].toLocaleString()} per week, £{formatMonthlyPrice(result.data[0])} to £{formatMonthlyPrice(result.data[1])} per month.</span>
                                ) : (
                                    <span>一个位于 {inputs.postcode} 的
                                        {_getChineseType(inputs.type as PropertyTypeKey).replace(/_/g, ' ')}
                                        ，有{inputs.bedrooms}间卧室，平均租金为每周£{result.data[0].toLocaleString()}至£{result.data[1].toLocaleString()}，即每月£{formatMonthlyPrice(result.data[0])}至£{formatMonthlyPrice(result.data[1])}。</span>
                                )}
                            </p>
                            <div className='mt-20 mb-10 opacity-50'>
                                <p>{lang ? "The rent estimate is calculated using big data to average the rents of similar listings around the postal code, and is for reference only. Actual rent may vary based on property condition, floor, and available facilities." : "预估租金计算根据大数据对邮编周围类似房源的租金取平均值，仅供参考。具体租金跟房况，楼层，配套设施等多种因素有关。"}</p>

                            </div>
                        </>
                    }
                </div>
            </div>
            {!error && result && result.status === "success" && result.data &&
                <div className={`${props.page !== "page" && "mb-20"} flex flex-col items-center`}>
                    <button className={`btn btn-sm px-4 py-2 rounded-lg  self-center ${props.page === "tool" ? "mt-10" : "mt-0 mb-10 sm:mb-60"}`} onClick={handleDownloadPDF}>
                        {isGeneratingPDF ? <span>{lang ? "Downloading PDF..." : "PDF 下载中..."}</span> : (
                            <span>{lang ? "Download PDF" : "下载 PDF"}</span>
                        )}
                    </button>
                    {/* {(isRealMobile && pdfUrl) &&
                        <div className="pdf-download-link">
                            <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download="NVRE Rental Income Estimation.pdf">
                                <span>{lang ? "Open PDF" : "打开 PDF"}</span>
                            </a>
                        </div >
                    } */}
                </div>
            }
        </div>

    );
}

function formatMonthlyPrice(price: any) {
    return (price * 52 / 12).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};;