import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ListBlock.scss";
import Info from "../Common/Info";
import { removeDevelopment } from "../../../../features/developments/developmentsSlice";
import {
  getEditMode,
  getIsRealMobile,
  setShowContactPopup,
} from "../../../../features/app/UIRelatedSlice";

export default function ListBlock(props: any) {
  const dispatch = useDispatch();
  const editMode = useSelector(getEditMode);
  const isRealMobile = useSelector(getIsRealMobile);

  return (
    <div
      className="development_small_container  text-gray-700 hover:cursor-pointer"
      onClick={() => {
        dispatch(setShowContactPopup(true));
      }}
    >
      <div className="grid_view_development" id={props.obj["id"]}>
        <div
          className={`flex-container with_img ${isRealMobile ? "mobile" : ""}`}
        >
          {editMode && (
            <button
              id="btn-list-remove"
              className="to-remove absolute top-[-10px] right-[-10px] text-lg bg-secondary-light p-5 rounded-full w-5 h-5 flex items-center justify-center text-white shadow-md"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(removeDevelopment(+props.obj.id));
              }}
            >
              ✕
            </button>
          )}
          <Info obj={props.obj} inGrid={true} />
        </div>
      </div>
    </div>
  );
}
