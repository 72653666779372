import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Development,
  getAllDevelopments,
  getKeywordFilteredFilteredDevelopmentIds,
  getKeywordFilteredUnfilteredDevelopmentIds,
} from "../../../features/developments/developmentsSlice";
import {
  addDevelopment,
  removeDevelopment,
} from "../../../features/developments/developmentsSlice";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { GridApi, ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "./TableView.scss";
import { AddFillIcon, RemoveFillIcon } from "../../../assets/assetExport";
import {
  getLayoutHeaderHeight,
  getLayoutMenubarHeight,
} from "../../../features/layouts/layoutsSlice";
import { SpinnerCircles } from "../../../util/Spinners/Spinners";
import { getLang } from "../../../features/app/UserRelatedSlice";
import { getEditMode } from "../../../features/app/UIRelatedSlice";

function importAll(r: any) {
  let images: any = {};
  r.keys().forEach((item: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context(
    "../../../assets/media/developmentImages",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const TableView = React.memo((props: any) => {
  const editMode = useSelector(getEditMode);
  const lang = useSelector(getLang);
  const keywordFilteredFilteredDevelopmentIds = useSelector(
    getKeywordFilteredFilteredDevelopmentIds
  );
  const keywordFilteredUnfilteredDevelopmentIds = useSelector(
    getKeywordFilteredUnfilteredDevelopmentIds
  );

  const allDevelopments = useSelector(getAllDevelopments);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const dispatch = useDispatch();
  const headerHeight = useSelector(getLayoutHeaderHeight);
  const menubarHeight = useSelector(getLayoutMenubarHeight);
  const [gridReady, setGridReady] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
    const columnIdsToAutoSize = ["completion"];
    params.api.autoSizeColumns(columnIdsToAutoSize);
    setGridReady(true);
  }, []);

  useEffect(() => {
    if (gridReady && keywordFilteredFilteredDevelopmentIds && allDevelopments) {
      setFinishLoading(true);
    }
  }, [
    gridReady,
    keywordFilteredFilteredDevelopmentIds,
    allDevelopments,
    setFinishLoading,
  ]);

  const handleCellStyle = () => {
    return { opacity: "0.5" };
  };

  const convertToArray = useCallback(
    (ids: number[]) => {
      return ids
        .map((id) => allDevelopments[id])
        .filter((dev): dev is Development => dev !== undefined);
    },
    [allDevelopments]
  );

  const onCellClicked = (params: any) => {
    if (!params.value) return;
    if (!navigator || !navigator.clipboard || !navigator.clipboard.writeText)
      return;
    navigator.clipboard
      .writeText(params.value)
      .then(() => {
        const copyNotification = document.createElement("div");
        copyNotification.textContent = "Copied";
        copyNotification.classList.add(
          "absolute",
          "bg-secondary-light",
          "text-white",
          "px-3",
          "py-1",
          "h-[80%]",
          "top-[50%]",
          "right-2",
          "-translate-y-[50%]",
          "flex",
          "items-center",
          "rounded-sm",
          "text-sm",
          "font-medium"
        );
        params.event.target.appendChild(copyNotification);
        setTimeout(() => {
          params.event.target.removeChild(copyNotification);
        }, 1000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  function compareIgnoreNull(
    valueA: any,
    valueB: any,
    nodeA: any,
    nodeB: any,
    isInverted: boolean
  ) {
    if (valueA === null && valueB === null) {
      return 0;
    }
    if (valueA === null) {
      return isInverted ? -1 : 1;
    }
    if (valueB === null) {
      return isInverted ? 1 : -1;
    }
    return valueA > valueB ? 1 : -1;
  }

  const columnDefs = (unselected: boolean): ColDef[] => {
    return [
      ...(editMode
        ? [
            {
              headerName: "",
              field: "id",
              pinned: true,
              width: 42,
              cellRenderer: (params: any) => {
                return (
                  <button
                    className="w-full h-full text-center"
                    onClick={() => {
                      if (unselected) {
                        dispatch(addDevelopment(+params.data.id));
                      } else {
                        dispatch(removeDevelopment(+params.data.id));
                      }
                    }}
                  >
                    <p
                      className={`mx-auto w-6 h-6  flex items-center justify-center rounded-full shadow-md shadow-gray-600/10 opacity-${
                        unselected ? "4" : "8"
                      }0`}
                    >
                      {unselected ? <AddFillIcon /> : <RemoveFillIcon />}
                    </p>
                  </button>
                );
              },
              cellClass: "button",
            },
          ]
        : []),
      {
        headerName: lang ? "Development" : "楼盘",
        field: "name",
        pinned: true,
        sortable: true,
        cellClass: "top-0",
        cellStyle: unselected ? handleCellStyle : {},
        sort: "asc",
        sortIndex: 0,
      },
      ...(!lang
        ? [
            {
              headerName: "别名",
              field: "nameCN",
              sortable: true,
              cellStyle: unselected ? handleCellStyle : {},
            },
          ]
        : []),
      {
        headerName: lang ? "Image" : "图片",
        field: "id",
        cellClass: "no-padding z-[9999999999]",
        cellRenderer: (params: any) => {
          const imageId = String(params.data.id).padStart(4, "0");
          const imageUrl = images[`NV${imageId.slice(-4)}.jpg`];
          return (
            <img
              src={imageUrl}
              alt={params.data.name}
              className="h-20 w-20 object-cover"
            />
          );
        },
        cellStyle: unselected ? handleCellStyle : {},
        width: 80,
      },
      {
        headerName: lang ? "Est. Completion" : "预计交房时间",
        field: "completion",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {lang
              ? params.data.completion
              : params.data.completion.replace("Completed", "现房")}
          </p>
        ),
        comparator: (
          valueA: any,
          valueB: any,
          nodeA: any,
          nodeB: any,
          isInverted: boolean
        ) => {
          const getYearAndQuarter = (value: string) => {
            let year = 0;
            let quarter = 0;
            let isCompleted = false;

            if (value === "Completed") {
              year = 0;
              quarter = 0;
              isCompleted = true;
            } else if (value.includes("Completed - ")) {
              year = parseInt(value.split(" - ")[1]);
              quarter = value.includes("Q") ? parseInt(value.split("Q")[1]) : 0;
              isCompleted = true;
            } else {
              year = parseInt(value.split(" ")[0]);
              quarter = value.includes("Q") ? parseInt(value.split("Q")[1]) : 0;
            }

            return { year, quarter, isCompleted };
          };

          const {
            year: yearA,
            quarter: quarterA,
            isCompleted: isCompletedA,
          } = getYearAndQuarter(valueA);
          const {
            year: yearB,
            quarter: quarterB,
            isCompleted: isCompletedB,
          } = getYearAndQuarter(valueB);

          if (isCompletedA && !isCompletedB) {
            return -1;
          } else if (!isCompletedA && isCompletedB) {
            return 1;
          } else if (
            (isCompletedA && isCompletedB) ||
            (!isCompletedA && !isCompletedB)
          ) {
            if (yearA !== yearB) {
              return isInverted
                ? yearA > yearB
                  ? 1
                  : -1
                : yearA < yearB
                ? -1
                : 1;
            } else {
              if (quarterA !== quarterB) {
                return isInverted
                  ? quarterA > quarterB
                    ? 1
                    : -1
                  : quarterA < quarterB
                  ? -1
                  : 1;
              }
            }
          }
          return 0;
        },
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Studio" : "开间",
        field: "bed0",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed0 ? (
              <span>£{params.data.bed0}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "1 Bed" : "一房",
        field: "bed1",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed1 ? (
              <span>£{params.data.bed1}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "2 Bed" : "二房",
        field: "bed2",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed2 ? (
              <span>£{params.data.bed2}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "3 Bed" : "三房",
        field: "bed3",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed3 ? (
              <span>£{params.data.bed3}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "4 Bed" : "四房",
        field: "bed4",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed4 ? (
              <span>£{params.data.bed4}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "5 Bed" : "五房",
        field: "bed5",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed5 ? (
              <span>£{params.data.bed5}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "6 Bed" : "六房",
        field: "bed6",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.bed6 ? (
              <span>£{params.data.bed6}</span>
            ) : (
              <span className="opacity-15">N/A</span>
            )}
          </p>
        ),
        comparator: compareIgnoreNull,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Zone" : "地铁区",
        field: "zoneMain",
        sortable: true,
        cellRenderer: (params: any) => (
          <p>
            {params.data.zoneMain === 99 ? (
              <span className="opacity-15">N/A</span>
            ) : (
              <span>
                {params.data.zoneMain}{" "}
                {params.data.zoneOther && " / " + params.data.zoneOther}
              </span>
            )}
          </p>
        ),
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Postcode" : "邮编",
        field: "postcode",
        sortable: true,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Borough" : "区域",
        field: "borough",
        sortable: true,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Station" : "最近车站",
        field: "station",
        sortable: true,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Distance" : "距离",
        field: "distance",
        sortable: true,
        cellRenderer: (params: any) => <p>{params.data.distance} km</p>,
        cellStyle: unselected ? handleCellStyle : {},
      },
      {
        headerName: lang ? "Houses" : "别墅",
        field: "house",
        sortable: true,
        sort: "asc",
        comparator: (valueA: any, valueB: any) => {
          if (valueA === true && valueB === false) return -1;
          if (valueA === false && valueB === true) return 1;
          return 0;
        },
        // cellRenderer: (params: any) => <p>{params.data.house ? <span>true</span> : <span className="opacity-15">false</span>}</p>,
        cellRenderer: (params: any) => (
          <p>
            {params.data.house ? (
              <span>✓</span>
            ) : (
              <span className="opacity-15">✕</span>
            )}
          </p>
        ),
        cellStyle: unselected ? handleCellStyle : {},
      },
    ];
  };

  return (
    <div
      className="bg-white h-full relative z-10 p-10 ag-theme-quartz overflow-hidden"
      // style={{
      //   height: "calc(100vh - " + (headerHeight + menubarHeight) + "px)",
      //   width: "100%",
      //   overflow: "auto",
      // }}
    >
      {!finishLoading && (
        <div className="absolute left-0 top-0 z-[999999999999] bg-gray-50 flex items-center justify-center h-full w-full">
          <SpinnerCircles />
        </div>
      )}
      <div style={{ height: editMode ? "calc((100% - 7.5rem) / 2)" : "100%" }}>
        {editMode && (
          <p className="font-bold text-xl mb-2 ml-2 text-gray-600">
            <span>{lang ? "Selected" : "已选"}</span>
            <span className="text-gray-400/80">
              ({keywordFilteredFilteredDevelopmentIds.length})
            </span>
          </p>
        )}
        <AgGridReact
          columnDefs={columnDefs(false)}
          rowData={convertToArray(keywordFilteredFilteredDevelopmentIds)}
          onGridReady={onGridReady}
          onCellClicked={onCellClicked}
        />
      </div>
      {editMode && (
        <div className="mt-20" style={{ height: "calc((100% - 7.5rem) / 2)" }}>
          <p className="font-bold text-xl mb-2 ml-2 text-gray-400/80">
            <span>{lang ? "Unselected" : "未选"}</span>
            <span className="text-gray-400/80">
              ({keywordFilteredUnfilteredDevelopmentIds.length})
            </span>
          </p>
          <AgGridReact
            columnDefs={columnDefs(true)}
            rowData={convertToArray(keywordFilteredUnfilteredDevelopmentIds)}
            onGridReady={onGridReady}
            onCellClicked={onCellClicked}
          />
        </div>
      )}
    </div>
  );
});

export default TableView;
