import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserRelatedState {
  lang: boolean;
  permission: number;
  adminOn: boolean;
  searchName: string;
  cookieState: {
    analytics: number;
    preference: number;
    privacy: number;
  };
}

const initialState: UserRelatedState = {
  lang: true,
  permission: 0,
  adminOn: false,
  searchName: "",
  cookieState: {
    analytics: 0,
    preference: 0,
    privacy: 0,
  },
};

export const userRelatedSlice = createSlice({
  name: "userrelated",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<boolean>) => {
      state.lang = action.payload;
    },
    setPermission: (state, action: PayloadAction<number>) => {
      state.permission = action.payload;
    },
    setAdminOn: (state, action: PayloadAction<boolean>) => {
      state.adminOn = action.payload;
    },
    setSearchName: (state, action: PayloadAction<string>) => {
      state.searchName = action.payload;
    },
    setCookieState: (
      state,
      action: PayloadAction<{
        analytics: number;
        preference: number;
        privacy: number;
      }>
    ) => {
      state.cookieState = { ...state.cookieState, ...action.payload };
    },
  },
});
// SELECTORS
export const getLang = (state: any) => state.userRelated.lang;
export const getPermission = (state: any) => state.userRelated.permission;
export const getAdminOn = (state: any) => state.userRelated.adminOn;
export const getSearchName = (state: any) => state.userRelated.searchName;
export const getCookieStateAnalytics = (state: any) =>
  state.userRelated.cookieState.analytics;
export const getCookieStatePreference = (state: any) =>
  state.userRelated.cookieState.preference;
export const getCookieStatePrivacy = (state: any) =>
  state.userRelated.cookieState.privacy;

export const {
  setLang,
  setPermission,
  setAdminOn,
  setSearchName,
  setCookieState,
} = userRelatedSlice.actions;

export default userRelatedSlice.reducer;
