import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './StampDutyPage.module.scss';
import { generatePDFDocumentFromRef } from '../../../util/PDFRelated/generatePDF';
import { NavLink } from 'react-router-dom';
import { getLang } from '../../../features/app/UserRelatedSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getIsRealMobile } from '../../../features/app/UIRelatedSlice';

/*
Test cases: All tested

A. UK buyer & first home
240000  0
250000  0
424999  0
425000  0
624999  9,999.95    1.6%
625000  10,000.00   1.6%    // table with relief, 0-425000
800000  27,500.00   3.4%
925000  33,750.00   3.6%
1000000 41,250.00   4.1%
1500000 91,250.00   6.1%
2000000 151,250.00  7.6%

B. UK buyer & second home
39999   0                   // table with relief, 0-39999
40000   1,200.00    3.0%
240000  7,200.00    3.0%
250000  7,500.00    3.0%
800000  51,500.00   6.4%
925000  61,500.00   6.6%
1000000 71,250.00   7.1%
1500000 136,250.00  9.1%
2000000 211,250.00  10.6%

C. non UK buyer & first home
240000  4,800.00    2.0%
250000  5,000.00    2.0%
424999  8,499.98    2.0%
425000  8,500.00    2.0%
624999  22,499.93   3.6%
625000  22,500.00   3.6%    // table with relief 0-425000
800000  43,500.00   5.4%
925000  52,250.00   5.6%
1000000 61,250.00   6.1%
1500000 121,250.00  8.1%
2000000 191,250.00  9.6%

C. non UK buyer & second home
39999   799.98      2.0%    // table with relief 39999
40000   2,000.00    5.0%
240000  12,000.00   5.0%
250000  12,500.00   5.0%
800000  67,500.00   8.4%
925000  80,000.00   8.6%
1000000 91,250.00   9.1%
1500000 166,250.00  11.1%
2000000 251,250.00  12.6%


 */


export default function StampDutyPage(props: any) {
    const [isFirstHome, setIsFirstHome] = useState(true);
    const [propertyPrice, setPropertyPrice] = useState(0);
    const [propertyPriceDisplay, setPropertyPriceDisplay] = useState('0');
    const [isNonUKResident, setIsNonUKResident] = useState(false);
    const [taxTable, setTaxTable] = useState({ status: "idle", data: [] });
    const [taxData, setTaxData] = useState({ status: "idle", data: { tax: 0, rate: 0 } });
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const lang = useSelector(getLang);
    const isRealMobile = useSelector(getIsRealMobile);
    const tableRef = useRef(null);
    const dispatch = useDispatch();

    function handleCalculate(e: any) {
        e.preventDefault();
        let bands = [
            { lower: 0, upper: 250000, rate: 0.00 },
            { lower: 250000, upper: 925000, rate: 0.05 },
            { lower: 925000, upper: 1500000, rate: 0.10 },
            { lower: 1500000, upper: Infinity, rate: 0.12 }
        ];

        // Surcharge rates
        const nonUKResidentSurcharge = isNonUKResident ? 0.02 : 0;
        const additionalPropertySurcharge = (!isFirstHome && +propertyPrice >= 40000) ? 0.05 : 0;

        // Adjust for first-time buyer exemption
        if (isFirstHome && +propertyPrice <= 625000) {
            bands[0].upper = 425000; // No SDLT on the first £425,000
            bands[1].lower = 425000;
            bands[1].upper = 625000; // Reduced rates up to £625,000
        }

        if (!isFirstHome && +propertyPrice < 40000) {
            bands = [
                { lower: 0, upper: 39999, rate: 0.00 },
            ];
        }

        let tax = 0;
        let tableData: any = [];
        let price = +propertyPrice;

        bands.forEach(band => {
            if (price > band.lower) {
                let effectiveRate = band.rate + nonUKResidentSurcharge + additionalPropertySurcharge;
                const taxableSum = Math.min(price, band.upper) - band.lower;
                const taxForBand = taxableSum * effectiveRate;
                tax += taxForBand;

                let taxBandText = band.upper === Infinity ? "£1,500,000+" : `£${band.lower.toLocaleString()} - £${band.upper.toLocaleString()}`;

                tableData.push({
                    taxBand: taxBandText,
                    rate: `${(effectiveRate * 100).toFixed(0)}%`,
                    taxableSum: `£${taxableSum.toLocaleString()}`,
                    tax: `£${taxForBand.toFixed(2)}`
                });
            }
        });



        setTaxTable({ status: "success", data: tableData });
        setTaxData({
            status: "success", data: {
                rate: tax ? parseFloat((tax / price * 100).toFixed(1)) : 0,
                tax: +tax.toFixed(2),
            }
        });
    }

    const handleChange = (e: any) => {
        setIsFirstHome(e.target.value === "first");
    };


    function handlePropertyPriceInput(e: any) {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '');
        const numericValue = parseInt(inputValue, 10);

        if (!isNaN(numericValue)) {
            setPropertyPrice(numericValue);
            setPropertyPriceDisplay(numericValue.toLocaleString());
        } else {
            setPropertyPrice(0);
            setPropertyPriceDisplay('0');
        }
    }

    function handleDownloadPDF() {
        setIsGeneratingPDF(true);
    }



    useEffect(() => {
        (async () => {

            if (isGeneratingPDF && tableRef.current) {
                const doc = await generatePDFDocumentFromRef(tableRef.current, props.page === "tool");
                if (doc) {
                    if (!isRealMobile) {
                        doc.save(`NVRE Stamp Duty Calculation.pdf`);
                    } else {
                        const pdfBlob = doc.output('blob');
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(pdfBlob);
                        link.download = 'NVRE Stamp Duty Calculation.pdf';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    setIsGeneratingPDF(false);
                }

            }
        })();
    }, [isGeneratingPDF]);

    return (

        <div className={`${styles.stampDutyPage} content-inner ${props.page !== "tool" && "p-6 sm:p-16 mb-[240px]"} font-main-sans max-w-[960px] mx-auto`}>
            <div className={` `}>
                {/* <div className={`${styles.stampDutyPage} content-inner p-6 sm:p-16 font-main-sans max-w-[960px] mx-auto ${isEnglish ? "isEnglish" : "isChinese"}`}> */}
                <h1 id="service-title" className='font-semibold mb-8 text-4xl'>{lang ? <span>Stamp Duty Land Tax (SDLT){props.page === "tool" ? <span> 2023/2024</span> : <span><br />Information and Calculator 2023/2024</span>}</span> : <span>印花税土地税 (SDLT){props.page === "tool" ? <span> 2023/2024</span> : <span><br /> 信息和计算器 2023/2024</span>}</span>}</h1>
                {props.page === "tool" &&
                    <NavLink target="_blank" rel="noopener noreferrer" className={"text-main/50  block -mt-6 mb-8 underline"} to="/services/stamp-duty-calculator">{lang ? "Learn more" : "了解更多"}</NavLink>
                }
                {props.page !== "tool" &&
                    <p className='font-main-sans font-light text-base'>{lang ? "Stamp Duty Land Tax (SDLT) is a tax paid on property or land purchases in England and Northern Ireland. The tax varies if the property is in Scotland (Land and Buildings Transaction Tax) or Wales (Land Transaction Tax). SDLT applies when you buy a freehold property, a new or existing leasehold, a property through a shared ownership scheme, or when land or property is transferred in exchange for payment." : "印花税土地税 (SDLT) 是在英格兰和北爱尔兰购买房产或土地时支付的税款。如果房产位于苏格兰（土地和建筑物交易税）或威尔士（土地交易税），税额会有所不同。当您购买不动产、新的或现有的租赁权、通过共有权方案购买的房产，或者在交换付款时转让土地或房产时，需要支付 SDLT。"}</p>
                }
                <div ref={tableRef} id="to-print-stamp-duty" className={`mt-12 ${props.page === "tool" ? "tool" : ""}`}>
                    <h2 >{lang ? "Calculate your Stamp Duty" : "计算您的印花税"}</h2>
                    <form id="margin-top" onSubmit={handleCalculate} className={`${styles.calculateForm} flex flex-col gap-3`} onChange={() => { setTaxData({ status: "idle", data: { tax: 0, rate: 0 } }); setTaxTable({ status: "idle", data: [] }); }}>
                        {/* <form onSubmit={handleCalculate} className={`${styles.calculateForm} flex flex-col gap-3`} onChange={() => { setTaxData({ status: "idle", data: { tax: 0, rate: 0 } }); setTaxTable({ status: "idle", data: [] }); setPdfUrl(""); }}> */}
                        <label className='hideSpinner flex items-center gap-3'>
                            <span className={`${props.page === "tool" && "font-bold"} text-nowrap`}>{lang ? "Property Price" : "房产价格"}</span>
                            <input className="input input-bordered  input-sm w-full max-w-xs bg-transparent" type='text' value={propertyPriceDisplay === "0" ? "" : propertyPriceDisplay} onChange={handlePropertyPriceInput} placeholder='500,000' />
                        </label>
                        <label className='flex items-center gap-3'>
                            <input
                                className='checkbox '
                                type="checkbox" checked={isNonUKResident} onChange={(e) => { setIsNonUKResident(e.target.checked); }} />
                            <span>{lang ? "The buyer is a non-UK resident" : "买家是非英国居民"}</span>
                        </label>
                        <div className='flex flex-col mt-3 sm:mt-0 sm:flex-row gap-3 sm:gap-6'>
                            <label className='flex items-center gap-3'>
                                <input
                                    className='radio radio-sm'
                                    type="radio"
                                    value="first"
                                    checked={isFirstHome}
                                    onChange={handleChange}
                                />
                                <span>{lang ? "Property is a first home" : "房产是第一套住房"}</span>
                            </label>
                            <label className='flex items-center gap-3'>
                                <input
                                    className='radio radio-sm'
                                    type="radio"
                                    value="second"
                                    checked={!isFirstHome}
                                    onChange={handleChange}
                                />
                                <span>{lang ? "Property is a buy to let or second home" : "房产是出租或第二套房"}</span>
                            </label>
                        </div>
                        {!showResult(taxData, taxTable) && <button className="btn btn-sm w-32 mt-6 bg-gray-100 text-gray-800" type="submit">
                            {lang ? "Calculate" : "计算"}
                        </button>}
                    </form>
                    {showResult(taxData, taxTable) && (
                        <>
                            <div className='w-full overflow-scroll'>
                                <h3 >
                                    <span className='text-main'>
                                        {lang ? "Results" : "结果"}
                                    </span>
                                </h3>
                                <table className='table w-full'>
                                    <thead>
                                        <tr className='text-base'>
                                            <th>
                                                {lang ? "Stamp Duty to pay:" : "应缴印花税："}
                                            </th>
                                            <th>
                                                {lang ? "Effective Rate:" : "有效税率："}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='font-semibold text-xl text-secondary-dark opacity-80'>
                                            <td>£{taxData.data.tax.toLocaleString()}</td>
                                            <td>{taxData.data.rate}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h3 className='text-3xl'>
                                    <span className='text-main'>

                                        {lang ? "Stamp Duty Calculation Breakdown" : "印花税计算细则"}
                                    </span>
                                </h3>
                                <table className='table w-full'>
                                    <thead>
                                        <tr>
                                            <th>{lang ? "Tax Band" : "税率区间"}</th>
                                            <th>%</th>
                                            <th>{lang ? "Taxable Sum" : "应税金额"}</th>
                                            <th>{lang ? "Tax" : "税额"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taxTable.data.map((row: any) => {
                                            return (
                                                <tr>
                                                    <td>{row.taxBand}</td>
                                                    <td>{row.rate}</td>
                                                    <td>{row.taxableSum}</td>
                                                    <td>{row.tax}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className='mt-20 mb-20 opacity-50'>
                                    <p>{lang ? "The amounts in the table are for reference only and are subject to actual occurrence;" : "表中的金额以实际发生为准，以上数据仅供参考；"}</p>
                                    <p>{lang ? "If you reside continuously for 183 days after coming to the UK, you may be eligible for a 2% tax refund for overseas buyers with a visa that allows continuous residence." : "来英后连续居住183天可以退2%海外买家税，但需要购房者有可以连续居住的签证。"}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {
                    showResult(taxData, taxTable) &&
                    <div className='flex flex-col items-center'>
                        <button className="btn  btn-sm m-0 mt-0" onClick={handleDownloadPDF}>
                            {isGeneratingPDF ?
                                <>
                                    {lang ? "Downloading PDF..." : "PDF 下载中..."}
                                </>
                                :
                                <>
                                    {lang ? "Download PDF" : "下载 PDF"}
                                </>
                            }
                        </button>
                        {/* {(isRealMobile && pdfUrl) && (
                            <div className="pdf-download-link">
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download="NVRE Stamp Duty Calculation.pdf">
                                    {lang ? "Open PDF" : "打开 PDF"}
                                </a>
                            </div>
                        )} */}
                    </div>
                }


            </div>
            {props.page !== "tool" &&
                <div className={`mt-36 text-gray-800 ${styles.aboutStampDuty}`}>
                    <h2>{lang ? "About Stamp Duty Land Tax" : "印花税计算方式"}</h2>
                    <h3>{lang ? "Stamp Duty Rates" : "印花税税率"}</h3>
                    <p>{lang ? "Residential Properties" : "住宅物业"}</p>
                    <table className='table text-gray-800'>
                        <tbody>
                            <tr>
                                <td>Up to £250,000</td>
                                <td>0%</td>
                            </tr>
                            <tr>
                                <td>£250,001 to £925,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td className='flex'>£925,001 to £1.5 {lang ? "million" : "百万"}</td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td className='flex'>Above £1.5 {lang ? "million" : "百万"}</td>
                                <td>12%</td>
                            </tr>
                        </tbody>
                    </table>

                    <h4>{lang ? "First-Time Buyers" : "首次购房者"}</h4>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Up to £425,000</td>
                                <td>0%</td>
                            </tr>
                            <tr>
                                <td>£425,001 to £625,000</td>
                                <td>5%</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>{lang ? "First-time buyer relief is not available for properties over £625,000" : "首次购房者减免不适用于超过 £625,000 的物业"}</p>

                    <h4>{lang ? "Additional Properties" : "额外物业"}</h4>
                    <p>{lang ? "An additional 5% on top of standard SDLT rates for buying new residential properties if you own more than one. Properties under £40,000 are not subject to the additional 5% tax." : "如果您拥有多于一处物业，购买新住宅物业的标准 SDLT 税率上将增加额外的 5%。价值 £40,000 以下的物业不需缴纳额外的 5% 税。"}</p>

                    <h4>{lang ? "Non-UK Residents" : "非英国居民"}</h4>
                    <p>{lang ? "A 2% surcharge for non-UK residents buying residential properties" : "非英国居民购买住宅物业需缴纳 2% 的附加费"}</p>

                    <h3>{lang ? "SDLT Thresholds" : "SDLT 门槛"}</h3>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>{lang ? "Standard Residential Properties:" : "标准住宅物业："}</td>
                                <td>£250,000</td>
                            </tr>
                            <tr>
                                <td>{lang ? "First-Time Buyers:" : "首次购房者："}</td>
                                <td>{lang ? "£425,000 (for properties worth £625,000 or less)" : "£425,000（适用于价值 £625,000 或以下的物业）"}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3>{lang ? "Eligibility and Reliefs" : "资格和减免"}</h3>
                    <p>{lang ? "Various SDLT reliefs available for first-time buyers, multiple dwellings, and in specific situations like property transfers due to divorce or for charitable purposes." : "首次购房者、多套住宅以及特定情况（如因离婚或慈善目的的房产转让）可享受各种 SDLT 减免。"}</p>

                    <h3>{lang ? "Calculation of SDLT" : "SDLT 的计算"}</h3>
                    <p>{lang ? "The total value you pay SDLT on (the 'consideration') usually includes the price paid for the property or land and can sometimes include other types of payments like goods, works or services, or the release from a debt." : "您支付 SDLT 的总价值（'考虑因素'）通常包括为房产或土地支付的价格，有时还可能包括货物、工程或服务的其他类型支付，或债务的解除。"}</p>

                    <h3>{lang ? "Payment and Deadlines" : "支付和截止日期"}</h3>
                    <p>{lang ? "SDLT should be filed and paid within 14 days of completion of the property purchase." : "应在房产购买完成后 14 天内提交并支付 SDLT。"}</p>

                    <h3>{lang ? "Exemptions" : "免税"}</h3>
                    <p>{lang ? "Exemptions from SDLT or filing a return include property transfers with no payment exchange, inheritances, transfers due to divorce, properties under a certain value, and properties bought under alternative financial arrangements like Sharia law." : "免除 SDLT 或提交退货的情况包括无支付交换的财产转让、继承、离婚转让、某一价值以下的财产，以及根据伊斯兰教法等替代金融安排购买的财产。"}</p>

                    <h3>{lang ? "Exemptions" : "豁免"}</h3>
                    <p>{lang ? "Exemptions from SDLT or filing a return include property transfers with no payment exchange, inheritances, transfers due to divorce, properties under a certain value, and properties bought under alternative financial arrangements like Sharia law." : "免除 SDLT 或提交退货的情况包括无支付交换的财产转让、继承、离婚转让、某一价值以下的财产，以及根据伊斯兰教法等替代金融安排购买的财产。"}</p>

                    <h3>{lang ? "Additional Information" : "附加信息"}</h3>
                    <p>{lang ? "This calculator is for general interest and should not replace financial advice." : "此计算器仅供一般参考，不应替代财务咨询。"}</p>
                    <p>{lang ? "Stamp duty can be added to mortgage loans, depending on the lender's terms." : "根据贷款人的条款，印花税可以加到抵押贷款上。"}</p>
                </div>
            }
        </div>

    );
}

function showResult(taxData: any, taxTable: any) {
    return taxData.status === "success" && taxTable.status === "success";
    // return taxData.tax !== 0 && taxTable && taxTable.length > 0;
}

