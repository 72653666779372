import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeDevelopment,
  getKeywordFilteredFilteredDevelopmentIds,
  Development,
  checkFilterChanged,
  checkKeywordChanged,
} from "../../../features/developments/developmentsSlice";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapView.scss";
import ReactDOMServer from "react-dom/server";
import Popup from "../Components/Common/Popup";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import { getAllDevelopments } from "../../../features/developments/developmentsSlice";
import { getSearchName } from "../../../features/app/UserRelatedSlice";
import {
  getEditMode,
  getIsMobile,
  getIsRealMobile,
  getMapUpdateTrigger,
  getShowFilter,
} from "../../../features/app/UIRelatedSlice";
import { SpinnerCircles } from "../../../util/Spinners/Spinners";
import { getLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3FpaSIsImEiOiJja3kzYXlnNHkwMGNpMnNwN2RtMGhxMjI4In0.RpbUFrXuGm4d_QjFNIYo9A";

const MapView = (props: any) => {
  // PROPS //
  const { isVisible, isFullScreen } = props;

  // TOOLS //
  const dispatch = useDispatch();

  // STATES //
  const [selectedDevelopment, setSelectedDevelopment] =
    useState<Development | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // REFS //
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker }>({});
  const popupsRef = useRef<{ [key: string]: mapboxgl.Popup }>({});
  const isInitializedRef = useRef(false);
  const isFirstLoadRef = useRef(true); // TODO: delete this if use default for all developments situation

  // SELECTORS //
  const searchName = useSelector(getSearchName);
  const isRealMobile = useSelector(getIsRealMobile);
  const isMobile = useSelector(getIsMobile);
  const allDevelopments = useSelector(getAllDevelopments);
  const keywordFilteredDevelopmentIds = useSelector(
    getKeywordFilteredFilteredDevelopmentIds
  );
  const changedFilters = useSelector(checkFilterChanged);
  const hasActiveFilters = Object.keys(changedFilters).length > 0;
  const hasActiveKeyword = useSelector(checkKeywordChanged);
  const menubarHeight = useSelector(getLayoutMenubarHeight);
  const editMode = useSelector(getEditMode);
  const showFilter = useSelector(getShowFilter);
  const mapUpdateTrigger = useSelector(getMapUpdateTrigger);

  // MEMOS //
  const filteredDevelopmentsKey = useMemo(() => {
    return JSON.stringify({
      triggerCount: mapUpdateTrigger,
    });
  }, [mapUpdateTrigger]);
  // const filteredDevelopmentsKey = useMemo(() => {
  //   return JSON.stringify({
  //     ids: keywordFilteredDevelopmentIds,
  //     hasFilters: hasActiveFilters,
  //     hasKeyword: hasActiveKeyword,
  //     triggerCount: mapUpdateTrigger, // Add this to force updates
  //   });
  // }, [
  //   keywordFilteredDevelopmentIds,
  //   hasActiveFilters,
  //   hasActiveKeyword,
  //   mapUpdateTrigger,
  // ]);

  // CALLBACK FUNCTIONS //
  const removeAllMarkersAndPopupsOnMap = useCallback(() => {
    if (!isRealMobile) {
      Object.keys(popupsRef.current).forEach((id) => {
        popupsRef.current[id].remove();
        delete popupsRef.current[id];
      });
    }

    Object.keys(markersRef.current).forEach((id) => {
      markersRef.current[id].remove();
      delete markersRef.current[id];
    });

    setSelectedDevelopment(null);
  }, [isRealMobile, setSelectedDevelopment]);

  const handleRemoveDevelopment = useCallback(
    (event: any) => {
      setSelectedDevelopment(event.target.id);
      if (!event.target.matches(".marker-remove")) return;

      const buttonId = event.target.id;

      if (markersRef.current[buttonId]) {
        markersRef.current[buttonId].remove();
        delete markersRef.current[buttonId];
      }

      dispatch(removeDevelopment(+buttonId));

      event.stopPropagation();
    },
    [dispatch]
  );

  // Add these functions at the top of the component
  const calculateBounds = useCallback(
    (developmentIds: number[]) => {
      if (
        !allDevelopments ||
        Object.keys(allDevelopments).length === 0 ||
        developmentIds.length === 0
      )
        return null;
      let minLng = Infinity;
      let maxLng = -Infinity;
      let minLat = Infinity;
      let maxLat = -Infinity;

      developmentIds.forEach((id) => {
        const development = allDevelopments[id];
        if (development) {
          minLng = Math.min(minLng, development.longitude);
          maxLng = Math.max(maxLng, development.longitude);
          minLat = Math.min(minLat, development.latitude);
          maxLat = Math.max(maxLat, development.latitude);
        }
      });

      if (
        minLng === Infinity ||
        maxLng === -Infinity ||
        minLat === Infinity ||
        maxLat === -Infinity
      )
        return null;

      // Ensure minimum bounds size to prevent excessive zoom
      const minBoundsSize = 0.02; // About 2km at London's latitude

      const lngSpan = maxLng - minLng;
      const latSpan = maxLat - minLat;

      if (lngSpan < minBoundsSize) {
        const center = (maxLng + minLng) / 2;
        minLng = center - minBoundsSize / 2;
        maxLng = center + minBoundsSize / 2;
      }

      if (latSpan < minBoundsSize) {
        const center = (maxLat + minLat) / 2;
        minLat = center - minBoundsSize / 2;
        maxLat = center + minBoundsSize / 2;
      }

      return {
        _sw: { lng: minLng, lat: minLat },
        _ne: { lng: maxLng, lat: maxLat },
      };
    },
    [allDevelopments]
  );

  const fitMapToBounds = useCallback(
    (bounds: any) => {
      if (!mapRef.current || !bounds) return;

      // Calculate padding based on conditions
      const basePadding = 50;
      const rightPadding =
        !props.onHomePage && !isMobile && showFilter ? 400 : basePadding;

      mapRef.current.fitBounds(
        [
          [bounds._sw.lng, bounds._sw.lat],
          [bounds._ne.lng, bounds._ne.lat],
        ],
        {
          padding: {
            top: basePadding,
            bottom: basePadding,
            left: basePadding,
            right: rightPadding, // 400px when filter is open on desktop
          },
          duration: 1000, // Animation duration in milliseconds
          essential: true, // This animation is essential for the map to work properly
        }
      );
    },
    [props.onHomePage, isMobile, showFilter]
  );

  const loadDataOnMap = useCallback(() => {
    let markersToLoad = keywordFilteredDevelopmentIds.length;

    keywordFilteredDevelopmentIds.forEach((id: any) => {
      if (allDevelopments[id] && !markersRef.current[id]) {
        const development = allDevelopments[id];

        const jsxString = ReactDOMServer.renderToStaticMarkup(
          <IncludeHouseIcon width="15" height="15" />
        );
        const domParser = new DOMParser();
        const svgElement = domParser.parseFromString(
          jsxString,
          "image/svg+xml"
        ).documentElement;
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const svgAsDataURL =
          "data:image/svg+xml," + encodeURIComponent(svgString);

        enum PurpleShades {
          Base99 = "#edeaf0",
          Base7 = "#edeaf0",
          Base6 = "#edeaf0",
          Base5 = "#edeaf0",
          Base4 = "#edeaf0",
          Base3 = "#edeaf0",
          Base2 = "#edeaf0",
          Base1 = "#edeaf0",
        }

        const color =
          PurpleShades[
            ("Base" + development.zoneMain) as keyof typeof PurpleShades
          ];

        const MyMarker = (
          <div
            id={String(development.id)}
            className={`border w-[100px] h-[30px] whitespace-nowrap border-secondary-dark/10 shadow-md rounded-lg font-normal text-secondary-dark flex flex-col justify-center gap-1 p-1 px-2 hover:cursor-pointer`}
            style={{ backgroundColor: color }}
          >
            <div
              id={String(development.id)}
              className="absolute right-1 top-1 inset-0 h-[80%] w-[30%] ml-auto rounded-lg bg-gradient-to-r from-transparent to-tertiary-light pointer-events-none"
            ></div>
            <p
              id={String(development.id)}
              className="flex gap-2 items-center pr-2 overflow-hidden"
            >
              {development.house && (
                <img
                  id={String(development.id)}
                  className="opacity-60"
                  src={svgAsDataURL}
                  alt="House Icon"
                />
              )}
              {development.name}
            </p>
            <button
              id={String(development.id)}
              className="marker-remove absolute top-[-10px] right-[-10px] text-lg bg-secondary-light p-5 rounded-full w-5 h-5 items-center justify-center text-white shadow-md"
            >
              ✕
            </button>
          </div>
        );

        const markerHtml = ReactDOMServer.renderToString(MyMarker);

        const markerEl = document.createElement("div");
        markerEl.innerHTML = markerHtml;
        markerEl.addEventListener("click", handleRemoveDevelopment);

        const marker = new mapboxgl.Marker(markerEl)
          .setLngLat([development.longitude, development.latitude])
          .addTo(
            mapRef.current || new mapboxgl.Map({ container: "map-container" })
          );

        markersRef.current[development.id] = marker;

        markersToLoad -= 1;
        if (markersToLoad === 0) {
          setIsLoading(false);
        }
      }
    });

    if (markersToLoad === 0) {
      setIsLoading(false);
    }
  }, [keywordFilteredDevelopmentIds, allDevelopments, handleRemoveDevelopment]);

  // EFFECTS //
  useEffect(() => {
    if (!isVisible || !mapRef.current) return;

    const defaultView = {
      center: [-0.0999, 51.5085224],
      zoom: 12,
    };

    // TODO: keep this if use default for all developments situation
    if (!hasActiveFilters && !hasActiveKeyword) {
      mapRef.current.easeTo({
        ...defaultView,
        center: defaultView.center as [number, number],
        duration: 1000,
      });
      return;
    }

    // TODO: use this if use default only for first load
    /*
    if (isFirstLoadRef.current) {
      mapRef.current.easeTo({
        ...defaultView,
        center: defaultView.center as [number, number],
        duration: 1000,
      });
      isFirstLoadRef.current = false;
      return;
    }
      */

    const bounds = calculateBounds(keywordFilteredDevelopmentIds);
    if (bounds) {
      fitMapToBounds(bounds);
    }
  }, [isVisible, filteredDevelopmentsKey, allDevelopments]);

  useEffect(() => {
    if (!mapRef.current && !isInitializedRef.current) {
      const mapContainer = document.getElementById("map");
      if (mapContainer) {
        mapContainer.innerHTML = "";
      }

      mapRef.current = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v12",
        center: [-0.0999, 51.5085224],
        zoom: 12,
      });

      // mapRef.current.on('load', () => {
      //   setIsLoading(false);
      // });

      mapRef.current.on("error", (e) => {
        console.error("Mapbox error:", e);
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");
      mapRef.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        "top-left"
      );

      isInitializedRef.current = true;
    }

    return () => {
      // Only clean up markers, not the map instance
      Object.values(markersRef.current).forEach((marker) => marker.remove());
      markersRef.current = {};
    };
  }, []);

  // Update markers only when visible
  useEffect(() => {
    if (isVisible && mapRef.current) {
      removeAllMarkersAndPopupsOnMap();
      loadDataOnMap();
    }
  }, [
    isVisible,
    searchName,
    keywordFilteredDevelopmentIds,
    loadDataOnMap,
    removeAllMarkersAndPopupsOnMap,
  ]);

  const resizeMapWithDelays = () => {
    if (!mapRef.current) return;

    mapRef.current.resize();

    // Series of delayed resizes to ensure the map updates
    const delays = [100, 200, 500];
    const timers = delays.map((delay) =>
      setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.resize();
          // Also try to force a re-render of the map
          mapRef.current.triggerRepaint();
        }
      }, delay)
    );

    return timers;
  };

  // Resize map when becoming visible or window resized
  useEffect(() => {
    if (!isVisible || !mapRef.current) return;

    let timers = resizeMapWithDelays() || [];

    const handleResize = () => {
      timers.forEach(clearTimeout);
      timers = resizeMapWithDelays() || [];
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      timers.forEach(clearTimeout);
    };
  }, [isVisible, isFullScreen]);

  // useEffect(() => {
  //   if (!mapShowing) return;
  //   removeAllMarkersAndPopupsOnMap();
  //   loadDataOnMap();
  // }, [searchName, mapShowing, keywordFilteredDevelopmentIds, dispatch, loadDataOnMap, removeAllMarkersAndPopupsOnMap]);

  useEffect(() => {
    removeAllMarkersAndPopupsOnMap();
    loadDataOnMap();
  }, [
    searchName,
    keywordFilteredDevelopmentIds,
    dispatch,
    loadDataOnMap,
    removeAllMarkersAndPopupsOnMap,
  ]);

  return (
    <>
      <div
        className="relative w-full"
        style={{
          height: props.onHomePage
            ? "100%"
            : `calc(100vh - ${menubarHeight}px)`,
        }}
      >
        {isLoading && (
          <div
            className={`${
              props.rounded ? "rounded-xl" : ""
            } absolute inset-0 flex items-center justify-center bg-secondary-[#ebe7e4] z-50`}
          >
            <SpinnerCircles />
          </div>
        )}
        <div
          id="map"
          className={`h-full ${props.rounded ? "rounded-xl" : ""} ${
            props.onHomePage
              ? "mapboxgl-map hide"
              : editMode
              ? "show mapboxgl-map"
              : "hide mapboxgl-map"
          }`}
        ></div>
      </div>
      {selectedDevelopment && isVisible && (
        <div
          className={`${
            props.isMapViewContainerAboveBottomScreen ? "absolute" : "fixed"
          } ${
            props.onHomePage ? "left-[50%] -translate-x-[50%]" : " left-[2.5vw]"
          } ${isMobile ? "w-[95vw]" : "w-auto"} bottom-20  z-50`}
        >
          <Popup
            obj={allDevelopments[+selectedDevelopment]}
            setCurrentObj={() => setSelectedDevelopment(null)}
            isMap={true}
          />
        </div>
      )}
    </>
  );
};

export default MapView;
