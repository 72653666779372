import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { getDevelopments } from "../../api/developments";
import { getPostcodeFilteredDevs } from "../../api/developments"; // Adjust path as needed
import { RootState } from "../../app/store";

export interface DevelopmentsMap {
  [id: number]: Development;
}

export interface Development {
  bed0: number | null;
  bed1: number | null;
  bed2: number | null;
  bed3: number | null;
  bed4: number | null;
  bed5: number | null;
  bed6: number | null;
  borough: string;
  completion: string;
  completionArr: string;
  developer: string;
  distance: number;
  house: boolean;
  id: number;
  inMap: boolean;
  latitude: number;
  lineNum: number | null;
  longitude: number;
  maxPrice: number;
  minPrice: number;
  name: string;
  nameCN: string;
  postcode: string;
  source: string;
  station: string;
  stationId: string;
  status: boolean;
  units: number | null;
  zoneMain: number;
  zoneOther: number | null;
}

const zoneValue = {
  "zone-one": 1,
  "zone-two": 2,
  "zone-three": 3,
  "zone-four": 4,
  "zone-five": 5,
  "zone-six": 6,
};

export interface FilterType {
  price: [number, number];
  zone: {
    "zone-one": boolean;
    "zone-two": boolean;
    "zone-three": boolean;
    "zone-four": boolean;
    "zone-five": boolean;
    "zone-six": boolean;
  };
  postcode: string;
  useRadius: boolean;
  radius: number;
  borough: string;
  bedrooms: {
    bed0: boolean;
    bed1: boolean;
    bed2: boolean;
    bed3: boolean;
    bed4: boolean;
    bed5: boolean;
  };
  developer: {
    berkeley: boolean;
    barratt: boolean;
    galliard: boolean;
  };
  "other-developer": string;
  completion: {
    Completed: boolean;
    yr2024: boolean;
    yr2025: boolean;
    yr2026: boolean;
    yr2027: boolean;
  };
  house: boolean;
}
export const initialFilter: FilterType = {
  price: [0, 0],
  zone: {
    "zone-one": false,
    "zone-two": false,
    "zone-three": false,
    "zone-four": false,
    "zone-five": false,
    "zone-six": false,
  },
  postcode: "",
  useRadius: false,
  radius: 1,
  borough: "",
  bedrooms: {
    bed0: false,
    bed1: false,
    bed2: false,
    bed3: false,
    bed4: false,
    bed5: false,
  },
  developer: {
    berkeley: false,
    barratt: false,
    galliard: false,
  },
  "other-developer": "",
  completion: {
    Completed: false,
    yr2024: false,
    yr2025: false,
    yr2026: false,
    yr2027: false,
  },
  house: false,
};

// INITIAL STATE
export const initialState = {
  data: {
    allDevelopments: {} as DevelopmentsMap,
    allIds: [] as number[],
    postcodeFilteredIds: null as number[] | null,
    removedIds: [] as number[],
    addedIds: [] as number[],
    filters: initialFilter,
    sortBy: {
      attribute: "name",
      order: "asc",
    },
    searchKeyword: null as string | null,
    priceOriginal: [0, 0],
  },
  status: {
    allDevelopments: "idle",
  },
  error: {
    allDevelopments: null as string | null,
  },
};

// ASYNC THUNKS
export const asyncFetchDevelopments = createAsyncThunk(
  "developments/fetchDevelopments",
  async () => {
    let developments = await getDevelopments();
    // developments = developments.slice(0, 40); // TODO: remove this
    const developmentsMap = developments.reduce((obj: any, item: any) => {
      obj[item.id] = item;
      return obj;
    }, {});
    return developmentsMap;
  }
);

export const fetchPostcodeFilteredDevelopments = createAsyncThunk(
  "developments/fetchPostcodeFiltered",
  async ({ postcode, radius }: { postcode: string; radius: number }) => {
    const formattedPostcode = postcode.replaceAll(" ", "").toUpperCase().trim();
    if (!formattedPostcode) return [];

    const developmentIds = await getPostcodeFilteredDevs({
      postcode: formattedPostcode,
      radius: Math.max(1, radius),
    });

    return developmentIds || [];
  }
);

// SLICE OBJECT
export const developmentsSlice = createSlice({
  name: "developments",
  initialState,
  reducers: {
    setAllDevelopments: (state, action) => {
      state.data.allDevelopments = action.payload;
    },
    setAllDevelopmentStatus: (state, action) => {
      state.status.allDevelopments = action.payload;
    },
    setAllDevelopmentsAndIds: (state, action) => {
      state.data.allDevelopments = action.payload;
      state.data.allIds = Object.keys(action.payload).map(Number);
    },
    setPriceOriginal: (state, action) => {
      state.data.priceOriginal = action.payload;
    },
    setFilters: (state, action) => {
      state.data.filters = action.payload;
    },
    setFilter: (
      state,
      action: PayloadAction<{ filter: keyof FilterType; value: any }>
    ) => {
      const { filter, value } = action.payload;
      (state.data.filters[filter] as any) = value;
    },
    setSearchKeyword: (state, action) => {
      state.data.searchKeyword = action.payload;
    },
    setNestedFilter: (
      state,
      action: PayloadAction<{
        filter: keyof FilterType;
        nestedFilter: string;
        value: any;
      }>
    ) => {
      const { filter, nestedFilter, value } = action.payload;
      if (
        state.data.filters[filter] &&
        typeof state.data.filters[filter] === "object"
      ) {
        (state.data.filters[filter] as any)[nestedFilter] = value;
      }
    },
    setSort: (state, action) => {
      const { attribute, order } = action.payload;
      state.data.sortBy = { attribute, order };
    },
    addDevelopment: (state, action: PayloadAction<number>) => {
      if (
        state.data.addedIds &&
        ((state.data.addedIds.length > 0 &&
          !state.data.addedIds.includes(action.payload)) ||
          state.data.addedIds.length === 0)
      ) {
        state.data.addedIds.push(action.payload);
      }
      state.data.removedIds =
        state.data.removedIds && state.data.removedIds.length > 0
          ? state.data.removedIds.filter((id: any) => id !== action.payload)
          : [];
    },
    removeDevelopment: (state, action: PayloadAction<number>) => {
      if (
        state.data.removedIds &&
        ((state.data.removedIds.length > 0 &&
          !state.data.removedIds.includes(action.payload)) ||
          state.data.removedIds.length === 0)
      ) {
        state.data.removedIds.push(action.payload);
      }
      state.data.addedIds =
        state.data.addedIds && state.data.addedIds.length > 0
          ? state.data.addedIds.filter((id: any) => id !== action.payload)
          : [];
    },
    clearFilter: (state) => {
      if (state && state.data && state.data.filters) {
        state.data.searchKeyword = null;
        state.data.filters = initialFilter;
        state.data.postcodeFilteredIds = null;
        state.data.removedIds = [];
        state.data.addedIds = [];
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(asyncFetchDevelopments.pending, (state) => {
        state.status.allDevelopments = "loading";
      })
      .addCase(asyncFetchDevelopments.fulfilled, (state, action) => {
        state.status.allDevelopments = "succeeded";
        state.data.allIds = Object.keys(action.payload).map(Number);
        state.data.allDevelopments = action.payload;
      })
      .addCase(asyncFetchDevelopments.rejected, (state, action) => {
        state.status.allDevelopments = "failed";
        state.error.allDevelopments = action.error.message ?? null;
      })
      .addCase(
        fetchPostcodeFilteredDevelopments.fulfilled,
        (state, action: any) => {
          state.data.postcodeFilteredIds = action.payload;
        }
      );
  },
});

// SELECTORS
// states
export const getAllDevelopments = (state: RootState) =>
  state.developments.data.allDevelopments || {};
export const getFilters = (state: RootState) =>
  state.developments.data.filters || initialFilter;
export const getAllIds = (state: any) => state.developments.data.allIds || [];
export const getRemovedIds = (state: any) =>
  state.developments.data.removedIds || [];
export const getAddedIds = (state: any) =>
  state.developments.data.addedIds || [];
export const getSortBy = (state: any) =>
  state.developments.data.sortBy || { attribute: "name", order: "asc" };
export const getPostcodeFilteredIds = (state: any) =>
  state.developments.data.postcodeFilteredIds || [];
export const getSearchKeyword = (state: any) =>
  state.developments.data.searchKeyword || "";
export const getPriceOriginal = (state: any) =>
  state.developments.data.priceOriginal || [0, 0];

const memoizedGetAllDevelopments = createSelector(
  [getAllDevelopments],
  (allDevelopments) => allDevelopments
);
const memoizedGetAllIds = createSelector([getAllIds], (allIds) => allIds);
const memoizedGetSearchKeyword = createSelector(
  [getSearchKeyword],
  (searchKeyword) => searchKeyword
);
const memoizedGetAddedIds = createSelector(
  [getAddedIds],
  (addedIds) => addedIds
);
const memoizedGetRemovedIds = createSelector(
  [getRemovedIds],
  (removedIds) => removedIds
);
const memoizedGetFilters = createSelector([getFilters], (filters) => filters);
const memoizedGetPostcodeFilteredIds = createSelector(
  [getPostcodeFilteredIds],
  (postcodeFilteredIds) => postcodeFilteredIds
);

export const getFilteredDevelopmentIds = createSelector(
  [
    memoizedGetAllDevelopments,
    memoizedGetAllIds,
    memoizedGetAddedIds,
    memoizedGetRemovedIds,
    memoizedGetFilters,
    memoizedGetPostcodeFilteredIds,
  ],
  (
    developments: DevelopmentsMap,
    allIds: number[],
    addedIds: number[],
    removedIds: number[],
    filters: any,
    postcodeFilteredIds: number[] | null
  ): number[] => {
    if (
      !developments ||
      Object.keys(developments).length === 0 ||
      !allIds ||
      allIds.length === 0 ||
      !filters
    ) {
      return []; // or [] depending on what you want to return when data is not available
    }
    let t = allIds
      .filter((id: number) => !removedIds.includes(id))
      .filter((id: number) => {
        const dev = developments[id];
        const passesZoneFilter = filterZone(dev);
        const passesPostcodeFilter = filters.useRadius
          ? filterPostcodeWithRadius(dev)
          : filterPostcode(dev);
        const passesBoroughFilter = filterBorough(dev);
        const passesBedroomsFilter = filterBedrooms(dev);
        const passesCompletionFilter = filterCompletion(dev);
        const passesHousesFilter = filterHouses(dev);
        const passesPriceFilter =
          filters.price[0] === 0 && filters.price[1] === 0
            ? true
            : filterPrice(dev);

        return (
          passesZoneFilter &&
          passesPostcodeFilter &&
          passesBoroughFilter &&
          passesBedroomsFilter &&
          passesCompletionFilter &&
          passesHousesFilter &&
          passesPriceFilter
        );
      });

    t = t.concat(addedIds.filter((id) => !t.includes(id)));

    return t;

    function filterZone(data: Development) {
      const selectedZones = filters.zone
        ? Object.keys(filters.zone).filter((key) => filters.zone[key])
        : [];
      if (selectedZones.length === 0) {
        return true;
      }
      return selectedZones.some((zone) => {
        const zoneKey = zone as keyof typeof zoneValue;
        if (zoneKey === "zone-six") {
          return data.zoneMain >= 6 || (data.zoneOther && data.zoneOther >= 6);
        } else {
          return (
            data.zoneMain === zoneValue[zoneKey] ||
            data.zoneOther === zoneValue[zoneKey]
          );
        }
      });
    }

    function filterPostcode(data: Development) {
      const postcode = filters.postcode
        ? filters.postcode.replace(/\s/g, "").toUpperCase()
        : "";
      if (!postcode) return true;
      return data.postcode.replace(/\s/g, "").startsWith(postcode);
    }

    function filterPostcodeWithRadius(data: Development) {
      if (!filters.useRadius || !filters.postcode) return true;

      if (!Array.isArray(postcodeFilteredIds)) return true;

      return postcodeFilteredIds.includes(data.id);
    }

    function filterPrice(data: Development) {
      const priceFilter = filters.price || [0, 0];
      const minPrice = priceFilter[0];
      const maxPrice = priceFilter[1];

      const bedrooms = Object.keys(filters.bedrooms);
      return bedrooms.some((bedroom) => {
        const bedroomPrice = data[bedroom as keyof Development];
        return (
          typeof bedroomPrice === "number" &&
          bedroomPrice !== 1 &&
          (minPrice === 0 || bedroomPrice >= minPrice) &&
          (maxPrice === 0 || bedroomPrice <= maxPrice)
        );
      });
    }

    function filterBorough(data: Development) {
      const borough = filters.borough
        ? filters.borough.toUpperCase().trim()
        : "";
      if (!borough) return true;
      return data.borough.toUpperCase().includes(borough);
    }

    function filterBedrooms(data: Development) {
      const selectedBedrooms = filters.bedrooms
        ? Object.keys(filters.bedrooms).filter((key) => filters.bedrooms[key])
        : [];
      if (selectedBedrooms.length === 0) {
        return true;
      }
      return selectedBedrooms.some((bedroom) => {
        if (bedroom === "bed5") {
          return data.bed5 || data.bed6;
        }
        return data[bedroom as keyof Development];
      });
    }

    function filterHouses(data: Development) {
      if (!filters.house) return true;
      return data.house;
    }

    function filterCompletion(data: Development) {
      const selectedCompletions = filters.completion
        ? Object.keys(filters.completion).filter(
            (key) => filters.completion[key]
          )
        : [];
      if (selectedCompletions.length === 0) {
        return true;
      }
      return selectedCompletions.some((completion) => {
        if (completion === "Completed") {
          return data.completion === "Completed";
        }
        const year = parseInt(completion.slice(2));
        return data.completion === year.toString();
      });
    }
  }
);

const memoizedGetFilteredDevelopmentIds = createSelector(
  [getFilteredDevelopmentIds],
  (filteredDevelopmentIds) => filteredDevelopmentIds
);

export const getUnfilteredDevelopmentIds = createSelector(
  [memoizedGetAllIds, memoizedGetFilteredDevelopmentIds],
  (allIds, filteredIds) => {
    const filteredIdSet = new Set(filteredIds);
    return allIds.filter((id: any) => !filteredIdSet.has(id));
  }
);

const memoizedGetUnfilteredDevelopmentIds = createSelector(
  [getUnfilteredDevelopmentIds],
  (unfilteredDevelopmentIds) => unfilteredDevelopmentIds
);

export const getKeywordFilteredFilteredDevelopmentIds = createSelector(
  [
    memoizedGetAllDevelopments,
    memoizedGetFilteredDevelopmentIds,
    memoizedGetSearchKeyword,
  ],
  (allDevelopments, filteredDevelopmentIds, searchKeyword) => {
    if (!searchKeyword) return filteredDevelopmentIds;

    const lowercaseKeyword = searchKeyword.toLowerCase();
    return filteredDevelopmentIds.filter((id: number) => {
      const dev = allDevelopments[id];
      return (
        (dev.name && dev.name.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.nameCN && dev.nameCN.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.postcode &&
          dev.postcode.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.borough && dev.borough.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.station && dev.station.toLowerCase().includes(lowercaseKeyword))
      );
    });
  }
);

export const getKeywordFilteredUnfilteredDevelopmentIds = createSelector(
  [
    memoizedGetAllDevelopments,
    memoizedGetUnfilteredDevelopmentIds,
    memoizedGetSearchKeyword,
  ],
  (allDevelopments, unfilteredDevelopmentIds, searchKeyword) => {
    if (!searchKeyword) return unfilteredDevelopmentIds;

    const lowercaseKeyword = searchKeyword.toLowerCase();
    return unfilteredDevelopmentIds.filter((id: number) => {
      const dev = allDevelopments[id];
      return (
        (dev.name && dev.name.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.nameCN && dev.nameCN.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.postcode &&
          dev.postcode.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.borough && dev.borough.toLowerCase().includes(lowercaseKeyword)) ||
        (dev.developer &&
          dev.developer.toLowerCase().includes(lowercaseKeyword))
      );
    });
  }
);

const memoizedGetKeywordFilteredFilteredDevelopmentIds = createSelector(
  [getKeywordFilteredFilteredDevelopmentIds],
  (keywordFilteredFilteredDevelopmentIds) =>
    keywordFilteredFilteredDevelopmentIds
);

const memoizedGetKeywordFilteredUnfilteredDevelopmentIds = createSelector(
  [getKeywordFilteredUnfilteredDevelopmentIds],
  (keywordFilteredUnfilteredDevelopmentIds) =>
    keywordFilteredUnfilteredDevelopmentIds
);

export const getSortedDevelopmentIds = createSelector(
  [
    memoizedGetKeywordFilteredFilteredDevelopmentIds,
    getSortBy,
    memoizedGetAllDevelopments,
  ],
  (keywordFilteredFilteredDevelopmentIds, sortBy, allDevelopments) => {
    const developmentsArray = keywordFilteredFilteredDevelopmentIds.map(
      (id: any) => allDevelopments[id]
    );

    const sortedDevelopments = [...developmentsArray].sort((a, b) => {
      if (sortBy.attribute === "price") {
        return sortBy.order === "asc"
          ? a.minPrice - b.minPrice
          : b.maxPrice - a.maxPrice;
      }
      if (sortBy.attribute === "name") {
        return sortBy.order === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      if (sortBy.attribute === "zone") {
        return sortBy.order === "asc"
          ? a.zoneMain - b.zoneMain
          : b.zoneMain - a.zoneMain;
      }
      return 0;
    });

    return sortedDevelopments.map((dev) => dev.id);
  }
);

export const checkFilterChanged = createSelector(
  [memoizedGetFilters, getPriceOriginal],
  (filterRules, priceOriginal) => {
    const changedFilters: any = {};
    // Compare current filter with initial filter
    for (const key in filterRules) {
      // Skip radius if postcode is empty
      if (key === "radius" && filterRules.postcode === "") {
        continue;
      }

      if (
        JSON.stringify(filterRules[key as keyof FilterType]) !==
        JSON.stringify(initialFilter[key as keyof FilterType])
      ) {
        if (key === "zone" || key === "bedrooms" || key === "completion") {
          changedFilters[key] = Object.keys(filterRules[key]).filter(
            (subKey) =>
              (filterRules[key as keyof FilterType] as Record<string, boolean>)[
                subKey
              ]
          );
        } else if (key === "price") {
          if (
            JSON.stringify(filterRules[key]) !== JSON.stringify(priceOriginal)
          ) {
            changedFilters[key] = filterRules[key];
          }
        } else {
          changedFilters[key] = filterRules[key as keyof FilterType];
        }
      }
    }

    // can't use this: return Object.keys(changedFilters).length > 0;
    // cause print need the whole object
    return changedFilters;
  }
);

export const checkKeywordChanged = createSelector(
  [memoizedGetSearchKeyword],
  (searchKeyword) => searchKeyword !== ""
);

// status
export const getAllDevelopmentsStatus = (state: any) =>
  state.developments.status.allDevelopments;

// EXPORTS
export const {
  setAllDevelopments,
  setAllDevelopmentStatus,
  setAllDevelopmentsAndIds,
  setPriceOriginal,
  setFilters,
  setFilter,
  clearFilter,
  setNestedFilter,
  setSort,
  addDevelopment,
  removeDevelopment,
  setSearchKeyword,
} = developmentsSlice.actions;
export default developmentsSlice.reducer;
