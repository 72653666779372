// import { axiosCustom } from "./axoisCustom";

// export const sendEmail = async (name, to, subject, content) => {
//     try {
//         const response = await axiosCustom.post("/send_email", {
//             name: name,
//             to: to,
//             subject: subject,
//             content: content
//         });
//         if (response.status === 200) return true;
//         if (response.status === 500) return false;
//         // if (!response.data) return false;
//         // store.dispatch(setAccessToken(response.data['accessToken']));
//         // return true;
//     } catch (err) {
//         console.error(err);
//     }
// };

import { API_ENDPOINT } from ".";

export const sendEmail = async (
  name: string,
  to: string,
  subject: string,
  content: string
) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/send-email`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        to: to,
        subject: subject,
        content: content,
      }),
    });
    if (response.status === 200) return true;
    if (response.status === 500) return false;
  } catch (err) {
    console.error(err);
  }
};
