import React, { useContext, useEffect, useState } from "react";
import {
  CalculatorIcon,
  HouseIcon,
  MortgageIcon,
  ToolboxIcon,
  YieldIcon,
} from "../../assets/assetExport";
import styles from "./ToolMenu.module.scss";
import StampDutyPage from "../../pages/Services/StampDutyPage/StampDutyPage";
import MortgagePage from "../../pages/Services/MorgagePage/MortgagePage";
import RentalYieldPage from "../../pages/Services/YieldPage/YieldPage";
import RentEstimatePage from "../../pages/Services/RentEstimatePage/RentEstimatePage";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsRealMobile,
  getIsSmallScreen,
  getShowFilter,
} from "../../features/app/UIRelatedSlice";
import { getLang } from "../../features/app/UserRelatedSlice";

export default function ToolMenu() {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const lang = useSelector(getLang);
  const isSmallScreen = useSelector(getIsSmallScreen);
  const isRealMobile = useSelector(getIsRealMobile);
  const [showingTools, setShowingTools] = useState(false);
  const showFilter = useSelector(getShowFilter);

  function closeTools() {
    setShowingTools(false);
    setShow(false);
    setSelected("");
  }

  const shouldShowMenu = !isSmallScreen || show || selected;

  return (
    <div
      id="driver_tools"
      className={`${
        isRealMobile && showFilter ? "invisible" : "visible"
      } fixed bottom-5 right-5 z-[999999999999] flex flex-col justify-between ${
        selected
          ? isSmallScreen
            ? "w-[92vw] h-[80vh]"
            : "w-[650px] h-[700px]"
          : ""
      }  ${
        selected
          ? "rounded-[1.8rem] bg-white p-2"
          : "p-0 rounded-3xl bg-fourth-light"
      } overflow-visible shadow-lg shadow-black/30 border border-gray-100`}
      style={{ minHeight: "0", maxHeight: `calc(100vh - 100px)` }}
    >
      {selected && (
        <div className={`overflow-y-scroll p-10`}>
          <p
            onClick={closeTools}
            className="absolute top-3 right-5 text-xl bg-white/50 rounded-lg p-2 text-secondary-light hover:cursor-pointer hover:text-main"
          >
            ✕
          </p>
          {selected === "mortgage" && <MortgagePage page="tool" />}
          {selected === "stampduty" && <StampDutyPage page="tool" />}
          {selected === "rental" && <RentalYieldPage page="tool" />}
          {selected === "rent" && <RentEstimatePage page="tool" />}
        </div>
      )}
      <div
        className="hover:cursor-pointer relative"
        onClick={() => isSmallScreen && setShow(true)}
        onMouseLeave={() => isSmallScreen && setShow(false)}
      >
        {shouldShowMenu && (
          <>
            {isSmallScreen && show && !selected && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(false);
                }}
                className="absolute top-0 -translate-y-full right-5 bg-secondary-light border border-white text-white/90 px-5 leading-6 text-xl rounded-t-lg"
              >
                ▾
              </div>
            )}
            <ul
              className={`${styles.items} ${
                !selected ? "flex-row" : styles.opened
              } ${
                isSmallScreen ? "flex-col" : ""
              } w-full justify-between bg-gray-100 rounded-3xl`}
            >
              <li
                onClick={() => {
                  setSelected("stampduty");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${
                    selected === "stampduty" ? styles.selected : ""
                  } hover:cursor-pointer`}
                >
                  <CalculatorIcon width="18" height="18" />
                  {lang ? "Stamp Duty" : "印花税计算器"}
                </p>
              </li>
              <li
                onClick={() => {
                  setSelected("rental");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${
                    selected === "rental" ? styles.selected : ""
                  } hover:cursor-pointer`}
                >
                  <YieldIcon width="18" height="18" />
                  {lang ? "Rental Yield" : "租金收益率"}
                </p>
              </li>
              <li
                onClick={() => {
                  setSelected("rent");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${
                    selected === "rent" ? styles.selected : ""
                  } hover:cursor-pointer`}
                >
                  <HouseIcon width="18" height="18" />
                  {lang ? "Rent Estimator" : "租金估算器"}
                </p>
              </li>
              <li
                onClick={() => {
                  setSelected("mortgage");
                  setShowingTools(true);
                }}
              >
                <p
                  className={`${
                    selected === "mortgage" ? styles.selected : ""
                  } hover:cursor-pointer`}
                >
                  <MortgageIcon width="18" height="18" />
                  {lang ? "Mortgage Calculator" : "贷款计算器"}
                </p>
              </li>
            </ul>
          </>
        )}
        {isSmallScreen && !shouldShowMenu && (
          <div className="flex items-center gap-2 px-3 py-1 opacity-80 rounded-full bg-fourth-light">
            <ToolboxIcon width="18" height="18" />
            <span className="text-secondary-dark px-2 font-medium border border-transparent">
              {lang ? "Landlord Tools" : "房东实用工具"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
